<template>
	<div class="result-box" ref="content">
		<div class="content-box" v-if="!showDetail">
			<div class="search-bar">
				<div class="left-title" @click="showDetailHandle">
					<Icon type="ios-list-box-outline" />估价报告
				</div>

				<div class="center">
					<Dropdown transfer transfer-class-name='optionBox' trigger="click" @on-click="changeSearch">
						<div class="searchOption">
							{{ yp.taskName }}
							<Icon class="downIcon" type="ios-arrow-down"></Icon>
						</div>
						<DropdownMenu slot="list">
							<DropdownItem :key="index" :name="item.id" v-for="(item, index) in searchList">
								{{ item.taskName }}</DropdownItem>
						</DropdownMenu>
					</Dropdown>
					<input type="text" v-model="searchValue" placeholder="请输入搜索内容" @click.stop @focus="inputSearch"
						@input="inputSearch" @keyup.enter="searchHandle" class="searchInput" />
					<Button class="searchBtn searchBtnLog" data-id="1" type="primary" :loading="searchLoading"
						@click.self="searchHandle">立即搜索</Button>

					<div class="searchTipsBox" v-if="searchHaveAddressList.length && showSearchList">
						<div class="searchTipsItem" v-for="(item, index) in searchHaveAddressList"
							@click.stop="chooseSearchAddress(item)" :key="index">
							<div class="key">{{ item.zzdz }}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="top-box">
				<div class="left-top-box">
					<div class="place-info">
						<div class="title">{{ yp.xqmc }}</div>
						<div class="time" v-if="yp.pgsj == ''">
							估价基准日：{{ yp.pgsj }}
						</div>
						<div class="time" v-else>
							估价基准日：{{ yp.pgsj | formatDate }}
						</div>
						<div class="address">
							<svg-icon class="mapIcon" iconClass="map"></svg-icon>
							<!-- <Tooltip :content="yp.zzdz" theme="light" max-width="300">{{yp.zzdz}}</Tooltip> -->
							<p :title="yp.zzdz">{{ yp.zzdz }}</p>
						</div>
						<!-- <div class="resultInfo" @click="showModal">
              <Icon type="ios-help-circle-outline" />估价说明
            </div> -->
						<div class="price">
							<div class="i-price">
								<div class="p-title">单价</div>
								<div class="p-num">{{ yp.pgdj }}元/m²</div>
								<div class="r-num">
									调整后单价{{
										yp.fjxzhzDj == ""
											? "--"
											: parseFloat(yp.fjxzhzDj).toFixed(2)
									}}元/m²
								</div>
							</div>
							<div class="i-price">
								<div class="p-title">总价</div>
								<div class="p-num">
									{{
										yp.pgzj ? parseFloat(yp.pgzj / 10000).toFixed(2) : "--"
									}}万元
								</div>
								<div class="r-num">
									调整后总价{{
										yp.fjxzhzZj == ""
											? "--"
											: parseFloat(yp.fjxzhzZj / 10000).toFixed(2)
									}}万元
								</div>
							</div>


						</div>
						<div class="referTips">
							<!-- 注：以上为中思拓大数据系统AI自动估价，价格仅供用户参考。 “政府参考价”请查阅政府相关部门网站。 -->
						</div>

						<div class="other-info">
							<div class="top-info">
								<div class="item">
									<span style="color: #333; font-size: 12px; margin-right: 10px">预警值</span>
									{{
										yp.yjz ? parseFloat(yp.yjz / 10000).toFixed(2) : "--"
									}}万元
								</div>
								<div class="item">
									<span style="color: #333; font-size: 12px; margin-right: 10px">风险预警</span>
									<span :style="{
										color:
											yp.yjLevel == 0
												? 'black'
												: yp.yjLevel == 1
													? 'orange'
													: yp.yjLevel == 2
														? 'yellow'
														: yp.yjLevel == 3
															? 'red'
															: 'black',
									}">{{
	yp.yjLevel == 0
		? "正常"
		: yp.yjLevel == 1
			? "橙色"
			: yp.yjLevel == 2
				? "黄色"
				: yp.yjLevel == 3
					? "红色"
					: "正常"
}}</span>
								</div>
							</div>
							<div class="bottom-info">
								<div class="item">
									<!-- <div>{{yp.dzyl==""?"70":yp.dzyl}}%</div> -->
									<div>{{ !yp.dzyl ? "--" : (yp.dzyl * 100).toFixed(2) }}%</div>
									<div style="color: #333; font-size: 12px">抵质押率</div>
								</div>
								<div class="item">
									<div>{{ yp.zgdzyl == "" ? "--" : yp.zgdzyl }}%</div>
									<div style="color: #333; font-size: 12px">最高抵质押率</div>
								</div>
								<div class="item">
									<div>
										{{ yp.zgkydbed ? (yp.zgkydbed / 10000).toFixed(2) : "--" }}万元
									</div>
									<div style="color: #333; font-size: 12px">
										最高可用担保额度
									</div>
								</div>
							</div>
						</div>
						<div class="info-box">
							<div class="item">
								<div class="info">租金价格</div>
								<div class="info">
									{{ yp.zjdjpg == "" ? "--" : parseFloat(yp.zjdjpg).toFixed(2) }}元/m²/月
								</div>
								<div class="info">
									调整后{{ yp.zjxzhzJg == "" ? "--" : parseFloat(yp.zjxzhzJg).toFixed(2) }}元/m²/月
								</div>
							</div>
							<div class="item">
								<div class="info">租金</div>
								<div class="info">
									{{ yp.zjzjpg == "" ? "--" : parseFloat(yp.zjzjpg).toFixed(2) }}元/月
								</div>
								<div class="info">调整后{{ yp.zjxzhzZj == "" ? "--" : parseFloat(yp.zjxzhzZj).toFixed(2) }}元/月
								</div>
							</div>
							<div class="item">
								<div class="info">售租比</div>
								<div class="info">{{ yp.zsb == "" ? "--" : yp.zsb }}月</div>
								<!--  <div class="info">修正后 21元/m²/月</div>-->
							</div>
						</div>
					</div>
					<div class="other-list">
						<div class="other-nav-list">
							<div class="item" :key="index" :class="activeTab == item.id ? 'active' : ''" @click="chooseTab(item)"
								v-for="(item, index) in tabs">
								{{ item.name }}
							</div>
						</div>
						<div v-if="activeTab == 0">
							<div class="other-tab">
								<!-- <div class="tipsInfo" @click="showTips">
									<Icon type="ios-help-circle-outline" />
								</div> -->
								<Tooltip content="数据来源于战略合作伙伴" theme="light">
									<Icon type="ios-help-circle-outline" />
								</Tooltip>

								<span @click="activeOtherTab = 2; _yp_cjal();" :class="activeOtherTab == 2 ? 'active' : ''">成交案例</span>
								<span @click="activeOtherTab = 1; _yp_gpal();" :class="activeOtherTab == 1 ? 'active' : ''">挂牌案例</span>
							</div>
							<div class="other-info-list" v-if="activeOtherTab == 1">
								<div class="item" :key="index" v-for="(item, index) in ckalGpList">
									<img v-if="item.ypImgUrl == ''" class="img" src="../../assets/image/buildingImg.jpg" />
									<img v-else class="img" :src="item.ypImgUrl" />
									<div class="title">{{ item.lpmc }}</div>
									<div class="size">
										<span>{{ item.mj }}m²</span>
										<span>{{ item.hx }}</span>
									</div>
									<div class="time">挂牌时间：{{ item.gpsj }}</div>
									<div class="priceItem sPrice">
										<div class="icon">单</div>
										<div class="price">{{ item.dj }}</div>
										<div class="dw">元/m²</div>
									</div>
									<div class="priceItem aPrice">
										<div class="icon">总</div>
										<div class="price">
											{{ parseFloat((item.dj * item.mj) / 10000).toFixed(0) }}
										</div>
										<div class="dw">万元</div>
									</div>
								</div>
							</div>
							<div class="other-info-list" v-if="activeOtherTab == 2">
								<div class="item" :key="index" v-for="(item, index) in ckalCjList">
									<img v-if="item.ypImgUrl == ''" class="img" src="../../assets/image/buildingImg.jpg" />
									<img v-else class="img" :src="item.ypImgUrl" />
									<div class="title">{{ item.lpmc }}</div>
									<div class="size">
										<span>{{ item.mj }}m²</span>
										<span>{{ item.hx }}</span>
									</div>
									<div class="time">成交时间：{{ item.cjsj }}</div>
									<div class="priceItem sPrice">
										<div class="icon">单</div>
										<div class="price">{{ item.dj }}</div>
										<div class="dw">元/m²</div>
									</div>
									<div class="priceItem aPrice">
										<div class="icon">总</div>
										<div class="price">
											{{ parseFloat((item.dj * item.mj) / 10000).toFixed(0) }}
										</div>
										<div class="dw">万元</div>
									</div>
								</div>
							</div>
						</div>
						<div v-else-if="activeTab == 1">
							<div class="other-info-list" style="height: 575px">
								<div class="item" :key="index" v-for="(item, index) in ckalList" @click="clickCkal(item)">
									<img v-if="item.ypImgUrl == ''" class="img" src="../../assets/image/buildingImg.jpg" />
									<img v-else class="img" :src="item.ypImgUrl" />

									<div class="title">{{ item.lpmc }}</div>
									<div class="address">
										<svg-icon class="mapIcon" iconClass="map"></svg-icon>
										<span>{{ item.juli }}米</span>
									</div>
									<div class="priceItem sPrice">
										<div class="icon">均</div>
										<div class="price">
											{{ item.dj ? parseFloat(item.dj).toFixed(0) : '--' }}
										</div>
										<div class="dw">元/m²</div>
									</div>
									<!-- <div class="priceItem aPrice">
                    <div class="icon">总</div>
                    <div class="price">{{parseFloat(item.zj/10000).toFixed(2)}}</div>
                    <div class="dw">万元</div>
                  </div>-->
								</div>
							</div>
						</div>
						<div v-else-if="activeTab == 2" :style="{ 'padding-top': '30px' }">
							<Tabs v-model="tab2" @on-click="chooseTab2">
								<TabPane label="交通" name="tab2">
									<div class="other-info-list" style="height: 492px; overflow: auto">
										<div class="itemT" :key="index" @click="clickPoint(item)" v-for="(item, index) in tabData['交通']">
											<div class="title" :title="item.title">
												{{ item.title }}
											</div>
											<div class="address">
												<svg-icon class="mapIcon" iconClass="map"></svg-icon>
												<span>{{ item.juli }}米</span>
											</div>
											<div v-for="(type, index) in item.tags" v-html="type" :key="type"
												:class="['type', `type-${index}`]"></div>
										</div>
									</div>
								</TabPane>
								<TabPane label="教育" name="tab3">
									<div class="other-info-list" style="height: 492px; overflow: auto">
										<div class="itemT" :key="index" @click="clickPoint(item)" v-for="(item, index) in tabData['教育']">
											<div class="title" :title="item.title">
												{{ item.title }}
											</div>
											<div class="address">
												<svg-icon class="mapIcon" iconClass="map"></svg-icon>
												<span>{{ item.juli }}米</span>
											</div>
											<div v-for="(type, index) in item.tags" v-html="type" :key="type"
												:class="['type', `type-${index}`]"></div>
										</div>
									</div>
								</TabPane>
								<TabPane label="医疗" name="tab4">
									<div class="other-info-list" style="height: 492px; overflow: auto">
										<div class="itemT" :key="index" @click="clickPoint(item)" v-for="(item, index) in tabData['医疗']">
											<div class="title" :title="item.title">
												{{ item.title }}
											</div>
											<div class="address">
												<svg-icon class="mapIcon" iconClass="map"></svg-icon>
												<span>{{ item.juli }}米</span>
											</div>
											<div v-for="(type, index) in item.tags" v-html="type" :key="type"
												:class="['type', `type-${index}`]"></div>
										</div>
									</div>
								</TabPane>
								<TabPane label="商业" name="tab5">
									<div class="other-info-list" style="height: 492px; overflow: auto">
										<div class="itemT" :key="index" @click="clickPoint(item)" v-for="(item, index) in tabData['商业']">
											<div class="title" :title="item.title">
												{{ item.title }}
											</div>
											<div class="address">
												<svg-icon class="mapIcon" iconClass="map"></svg-icon>
												<span>{{ item.juli }}米</span>
											</div>
											<div v-for="(type, index) in item.tags" v-html="type" :key="type"
												:class="['type', `type-${index}`]"></div>
										</div>
									</div>
								</TabPane>
								<TabPane label="特殊" name="tab1">
									<div class="other-info-list" style="height: 492px; overflow: auto">
										<div class="itemT" :key="index" @click="clickPoint(item)" v-for="(item, index) in tabData['厌恶']">
											<div class="title" :title="item.title">
												{{ item.title }}
											</div>
											<div class="address">
												<svg-icon class="mapIcon" iconClass="map"></svg-icon>
												<span>{{ item.juli }}米</span>
											</div>
											<div v-for="(type, index) in item.tags" v-html="type" :key="type"
												:class="['type', `type-${index}`]"></div>
										</div>
									</div>
								</TabPane>
							</Tabs>
							<div class="fanwei">
								<Input v-model="searchWidth" size="small" placeholder="单位：公里" maxlength="4" search enter-button
									style="width: 300px" @on-search="searchByWidth">
								<span slot="prepend">范围：（0-5）公里</span>
								</Input>
							</div>
						</div>

						<div class="tabInfo" v-else-if="activeTab == 3">
							<div class="info">
								<div class="line" v-show="xqxq.lpmc">
									<div class="name">小区名称</div>
									<div class="data norwap">
										<Tooltip theme="light" max-width="900" :content="xqxq.lpmc" word-wrap :transfer="true"
											placement="bottom">{{ xqxq.lpmc }}</Tooltip>
									</div>
								</div>
								<div class="line" v-show="xqxq.qx">
									<div class="name">行政区</div>
									<div class="data">
										<Tooltip theme="light" max-width="900" :content="xqxq.qx" word-wrap :transfer="true"
											placement="bottom">{{ xqxq.qx }}</Tooltip>
									</div>
								</div>
								<div class="line" v-show="xqxq.sq">
									<div class="name">板块/商圈</div>
									<div class="data norwap">
										<Tooltip theme="light" max-width="900" :transfer="true" :content="xqxq.sq" word-wrap
											placement="bottom">{{ xqxq.sq }}</Tooltip>
									</div>
								</div>
								<div class="line" v-show="xqxq.sldz">
									<div class="name">小区地址</div>

									<div class="data norwap">
										<Tooltip theme="light" max-width="900" :transfer="true" :content="xqxq.sldz" word-wrap
											placement="bottom">{{ xqxq.sldz }}</Tooltip>
									</div>
								</div>

								<div class="line" v-show="xqxq.wylb">
									<div class="name">物业类型</div>
									<div class="data">
										<Tooltip theme="light" max-width="900" :transfer="true" :content="xqxq.wylb" word-wrap
											placement="bottom">{{ xqxq.wylb }}</Tooltip>
									</div>
								</div>

								<div class="line" v-show="xqxq.cqnx">
									<div class="name">产权年限</div>
									<div class="data">
										<Tooltip theme="light" max-width="900" :transfer="true" :content="xqxq.cqnx" word-wrap
											placement="bottom">{{ xqxq.cqnx }}</Tooltip>
									</div>
								</div>

								<div class="line" v-show="xqxq.kfs">
									<div class="name">开发商</div>
									<div class="data">
										<Tooltip theme="light" max-width="900" :transfer="true" :content="xqxq.kfs" word-wrap
											placement="bottom">{{ xqxq.kfs }}</Tooltip>
									</div>
								</div>
								<div class="line" v-show="xqxq.jzlb">
									<div class="name">建筑类型</div>
									<div class="data">
										<Tooltip theme="light" max-width="900" :transfer="true" :content="xqxq.jzlb" word-wrap
											placement="bottom">{{ xqxq.jzlb }}</Tooltip>
									</div>
								</div>
								<div class="line" v-show="xqxq.jzjg">
									<div class="name">建筑结构</div>
									<div class="data">
										<Tooltip theme="light" max-width="900" :transfer="true" :content="xqxq.jzjg" word-wrap
											placement="bottom">{{ xqxq.jzjg }}</Tooltip>
									</div>
								</div>
								<div class="line" v-show="xqxq.zxzk">
									<div class="name">装修状况</div>
									<div class="data">
										<Tooltip theme="light" max-width="900" :transfer="true" :content="xqxq.zxzk" word-wrap
											placement="bottom">{{ xqxq.zxzk }}</Tooltip>
									</div>
								</div>
								<div class="line" v-show="xqxq.dtpz">
									<div class="name">电梯配置</div>
									<div class="data">
										<Tooltip theme="light" max-width="900" :transfer="true" :content="xqxq.dtpz" word-wrap
											placement="bottom">{{ xqxq.dtpz }}</Tooltip>
									</div>
								</div>
								<div class="line" v-show="xqxq.jznd">
									<div class="name">建筑年代</div>
									<div class="data">
										<Tooltip theme="light" max-width="900" :transfer="true" :content="xqxq.jznd" word-wrap
											placement="bottom">{{ xqxq.jznd }}</Tooltip>
									</div>
								</div>
								<div class="line" v-show="xqxq.zthx">
									<div class="name">主力户型</div>
									<div class="data">
										<Tooltip theme="light" max-width="900" :transfer="true" :content="xqxq.zthx" word-wrap
											placement="bottom">{{ xqxq.zthx }}</Tooltip>
									</div>
								</div>
								<div class="line" v-show="xqxq.kpsj">
									<div class="name">开盘时间</div>
									<div class="data">
										<Tooltip theme="light" max-width="900" :transfer="true" :content="xqxq.kpsj" word-wrap
											placement="bottom">{{ xqxq.kpsj }}</Tooltip>
									</div>
								</div>
								<div class="line" v-show="xqxq.jfsj">
									<div class="name">交房时间</div>
									<div class="data">
										<Tooltip theme="light" max-width="900" :transfer="true" :content="xqxq.jfsj" word-wrap
											placement="bottom">{{ xqxq.jfsj }}</Tooltip>
									</div>
								</div>
								<div class="line" v-show="xqxq.zdmj">
									<div class="name">占地面积</div>
									<div class="data">
										<Tooltip theme="light" max-width="900" :transfer="true" :content="xqxq.zdmj" word-wrap
											placement="bottom">{{ xqxq.zdmj }}m²</Tooltip>
									</div>
								</div>
								<div class="line" v-show="xqxq.jzmj">
									<div class="name">建筑面积</div>
									<div class="data">
										<Tooltip theme="light" max-width="900" :transfer="true" :content="xqxq.jzmj" word-wrap
											placement="bottom">{{ xqxq.jzmj }}m²</Tooltip>
									</div>
								</div>
								<div class="line" v-show="xqxq.zhs">
									<div class="name">总户数</div>
									<div class="data">
										<Tooltip theme="light" max-width="900" :transfer="true" :content="xqxq.zhs" word-wrap
											placement="bottom">{{ xqxq.zhs }}</Tooltip>
									</div>
								</div>
								<div class="line" v-show="xqxq.ldzs">
									<div class="name">楼栋总数</div>
									<div class="data">
										<Tooltip theme="light" max-width="900" :transfer="true" :content="xqxq.ldzs" word-wrap
											placement="bottom">{{ xqxq.ldzs }}</Tooltip>
									</div>
								</div>
								<div class="line" v-show="xqxq.lhl">
									<div class="name">绿化率</div>
									<div class="data">
										<Tooltip theme="light" max-width="900" :transfer="true" :content="xqxq.lhl" word-wrap
											placement="bottom">{{ xqxq.lhl }}</Tooltip>
									</div>
								</div>
								<div class="line" v-show="xqxq.rjl">
									<div class="name">容积率</div>
									<div class="data">
										<Tooltip theme="light" max-width="900" :transfer="true" :content="xqxq.rjl" word-wrap
											placement="bottom">{{ xqxq.rjl }}</Tooltip>
									</div>
								</div>
								<div class="line" v-show="xqxq.tcw">
									<div class="name">停车位数</div>
									<div class="data norwap">
										<Tooltip theme="light" max-width="900" :transfer="true" :content="xqxq.tcw" word-wrap
											placement="bottom">{{ xqxq.tcw }}</Tooltip>
									</div>
								</div>
								<div class="line" v-show="xqxq.tcwpb">
									<div class="name">停车位配比</div>
									<div class="data norwap">
										<Tooltip theme="light" max-width="900" :transfer="true" :content="xqxq.tcwpb" word-wrap
											placement="bottom">{{ xqxq.tcwpb }}</Tooltip>
									</div>
								</div>
								<div class="line" v-show="xqxq.wygs">
									<div class="name">物业公司</div>
									<div class="data norwap">
										<Tooltip theme="light" max-width="900" :transfer="true" :content="xqxq.wygs" word-wrap
											placement="bottom">{{ xqxq.wygs }}</Tooltip>
									</div>
								</div>
								<div class="line" v-show="xqxq.wyf">
									<div class="name">物业费</div>
									<div class="data norwap">
										<Tooltip theme="light" max-width="900" :transfer="true" :content="xqxq.wyf" word-wrap
											placement="bottom">{{ xqxq.wyf }}元/m²/月</Tooltip>
									</div>
								</div>
							</div>
						</div>

						<div class="tabInfo" v-else-if="activeTab == 4">
							<div id="chart9"></div>
						</div>
					</div>
				</div>
				<div class="right-top-box">
					<div class="map" id="map"></div>
					<div class="map-table">
						<div class="top-tab">
							<div class="item" @click="changeMapTab(1)" :class="{ active: mapTab == 1 }">
								估价历史
							</div>
							<div class="item" @click="changeMapTab(2)" :class="{ active: mapTab == 2 }">
								业内估价参考
							</div>
							<div class="item" @click="changeMapTab(3)" :class="{ active: mapTab == 3 }">
								估价对象详情
							</div>
						</div>
						<!-- <div class="more"
                 v-if="mapTab==3"
            @click="showDetailHandle">更多 >></div>-->
						<div class="tabInfo" v-if="mapTab == 3">
							<div class="info">
								<div class="line" v-show="ypXq.cs && ypXq.xzq">
									<div class="name">所属区域</div>
									<div class="data">{{ ypXq.cs }}市{{ ypXq.xzq }}</div>
								</div>
								<div class="line" v-show="ypXq.zzdz">
									<div class="name">证载地址</div>

									<div class="data">
										<Tooltip theme="light" max-width="900" style="width: 100%" :content="ypXq.zzdz" word-wrap
											placement="bottom">
											<span class="noWrap">{{ ypXq.zzdz }}</span>
										</Tooltip>
									</div>
								</div>
								<div class="line" v-show="ypXq.xqmc">
									<div class="name">小区名称</div>
									<div class="data" @click="toRegionResult">
										{{ ypXq.xqmc }}
									</div>
								</div>
								<div class="line" v-show="ypXq.ld">
									<div class="name">楼栋</div>
									<div class="data">{{ ypXq.ld }}</div>
								</div>
								<div class="line" v-show="ypXq.dy">
									<div class="name">单元</div>
									<div class="data">{{ ypXq.dy }}单元</div>
								</div>
								<div class="line" v-show="ypXq.fh">
									<div class="name">房号</div>
									<div class="data">{{ ypXq.fh }}房</div>
								</div>

								<div class="line" v-show="ypXq.jzmj">
									<div class="name">建筑面积</div>
									<div class="data">{{ ypXq.jzmj }}㎡</div>
								</div>
								<div class="line" v-show="ypXq.zlc && ypXq.szlc">
									<div class="name">所在楼层/总楼层</div>
									<div class="data">{{ ypXq.szlc }}/{{ ypXq.zlc }}</div>
								</div>
								<div class="line" v-show="ypXq.fwhx">
									<div class="name">房屋户型</div>
									<div class="data">{{ ypXq.fwhx }}</div>
								</div>

								<div class="line" v-show="ypXq.cx">
									<div class="name">朝向</div>
									<div class="data">{{ ypXq.cx }}</div>
								</div>
								<div class="line" v-show="ypXq.wylx">
									<div class="name">物业类型</div>
									<div class="data">{{ ypXq.wylx }}</div>
								</div>
								<div class="line" v-show="ypXq.jcnd">
									<div class="name">建筑年代</div>
									<div class="data">{{ ypXq.jcnd }}年</div>
								</div>
								<div class="line" v-show="ypXq.zxzk">
									<div class="name">户型结构</div>
									<div class="data">{{ ypXq.zxzk }}</div>
								</div>
								<div class="line" v-show="ypXq.jzjg">
									<div class="name">建筑结构</div>
									<div class="data">{{ ypXq.jzjg }}</div>
								</div>
								<div class="line" v-show="ypXq.jzlx">
									<div class="name">建筑类型</div>
									<div class="data">{{ ypXq.jzlx }}</div>
								</div>
							</div>
						</div>
						<Table border v-else-if="mapTab == 1" class="table" tooltip-theme="light" height="412" :columns="ypLs.key"
							:data="ypLs.data"></Table>
						<Table border v-else-if="mapTab == 2" class="table" tooltip-theme="light" height="412" :columns="ynpg.key"
							:data="ynpg.data"></Table>
					</div>
				</div>
			</div>
			<div class="bottom-table">
				<tableBox title="本小区估价对象列表" :tableOption="tableOption1" :showFunctionBar="false" :total="xqyp.total"
					@changePage="changeXqyp" :columns="xqyp.key" :current="params.current" @pageSizeChange="pageSizeChangeXqyp"
					:data="xqyp.data">
				</tableBox>

			</div>
		</div>
		<div class="detail" v-else>
			<Button @click="hideDetailHandle" class="backBtn">返回</Button>
			<Button type="primary" class="downloadBtn" @click="toImage">下载</Button>
			<div class="detailBox" ref="detail">
				<div class="title">{{ yp.zzdz }}估价报告</div>
				<div class="sData">
					<span>估价委托方：{{ yp.wtf }}</span>
					<span>估价方：{{ yp.gjf }}</span>
					<span>估价基准日：{{ yp.pgsj | formatDate }}</span>
				</div>
				<div class="grayLine"></div>
				<div class="detailItem">
					<div class="itemTitle">一、估价对象及估价结果</div>
					<div class="itemInfo">估价对象：{{ yp.zzdz }}</div>
					<div class="sTitle">估价结果</div>
					<div class="resultBox">
						<div class="resultItem">
							<div class="info">单价</div>
							<div class="info">
								<h4>
									{{ yp.pgdj ? parseFloat(yp.pgdj).toFixed(0) : "--" }}元/m²
								</h4>
							</div>
							<div class="info">
								调整后单价{{ yp.fjxzhzDj ? parseFloat(yp.fjxzhzDj).toFixed(2) : "--" }}元/m²
							</div>
						</div>
						<div class="resultItem">
							<div class="info">总价</div>
							<div class="info">
								<h4>
									{{ yp.pgzj ? parseFloat(yp.pgzj / 10000).toFixed(2) : "--" }}万元
								</h4>
							</div>
							<div class="info">
								调整后总价{{ yp.fjxzhzZj == "" ? "--" : parseFloat(yp.fjxzhzZj / 10000).toFixed(2) }}万元
							</div>
						</div>
						<div class="resultItem">
							<div class="info">租金价格</div>
							<div class="info">
								<h4>{{ yp.zjdjpg ? parseFloat(yp.zjdjpg).toFixed(2) : "--" }}元/m²/月</h4>
							</div>
							<div class="info">调整后{{ yp.zjxzhzJg ? parseFloat(yp.zjxzhzJg).toFixed(2) : "--" }}元/m²/月
							</div>
						</div>
						<div class="resultItem">
							<div class="info">租金</div>
							<div class="info">
								<h4>
									{{ yp.zjzjpg ? parseFloat(yp.zjzjpg).toFixed(2) : "--" }}元/月
								</h4>
							</div>
							<div class="info">调整后{{ yp.zjxzhzZj ? parseFloat(yp.zjxzhzZj).toFixed(2) : "--" }}元/月
							</div>
						</div>
						<div class="resultItem">
							<div class="info">售租比</div>
							<div class="info">
								<h4>{{ yp.zsb == "" ? "--" : yp.zsb }}月</h4>
							</div>
						</div>
						<div class="resultItem danger">
							<div class="info">预警值</div>
							<div class="info">
								<h4>{{ yp.yjz ? parseFloat(yp.yjz / 10000).toFixed(2) : "--" }}万元</h4>
							</div>
						</div>
						<div class="resultItem danger">
							<div class="info">风险预警</div>
							<div class="info">
								<h4 :style="{
									color:
										yp.yjLevel == 0
											? 'black'
											: yp.yjLevel == 1
												? 'orange'
												: yp.yjLevel == 2
													? 'yellow'
													: yp.yjLevel == 3
														? 'red'
														: 'black',
								}">
									{{
										yp.yjLevel == 0
											? "正常"
											: yp.yjLevel == 1
												? "橙色"
												: yp.yjLevel == 2
													? "黄色"
													: yp.yjLevel == 3
														? "红色"
														: "正常"
									}}
								</h4>
							</div>
						</div>
					</div>
					<div class="sTitle">估价历史</div>
					<Table border class="table" tooltip-theme="light" :columns="ypLs.key" :data="ypLs.data"></Table>
					<div class="infoBox">
						<div class="left">
							<div class="sTitle">估价对象详情</div>
							<div class="detailInfo">
								<div class="info">
									<div class="line" v-if="ypXq.cs && ypXq.xzq">
										<div class="name">所属区域</div>
										<div class="data">{{ ypXq.cs }}{{ ypXq.xzq }}</div>
									</div>
									<div class="line" v-if="ypXq.zzdz">
										<div class="name">证载地址</div>
										<div class="data norwap">
											<Tooltip theme="light" max-width="900" style="width: 100%" :content="ypXq.zzdz" word-wrap
												placement="bottom">{{ ypXq.zzdz }}
											</Tooltip>
										</div>
									</div>
									<div class="line" v-if="ypXq.xqmc">
										<div class="name">小区名称</div>
										<div class="data" @click="toRegionResult">
											{{ ypXq.xqmc }}
										</div>
									</div>
									<div class="line" v-if="ypXq.ld">
										<div class="name">楼栋</div>
										<div class="data">{{ ypXq.ld }}栋</div>
									</div>
									<div class="line" v-if="ypXq.dy">
										<div class="name">单元</div>
										<div class="data">{{ ypXq.dy }}单元</div>
									</div>
									<div class="line" v-if="ypXq.fh">
										<div class="name">房号</div>
										<div class="data">{{ ypXq.fh }}室</div>
									</div>

									<div class="line" v-if="ypXq.jzmj">
										<div class="name">建筑面积</div>
										<div class="data">{{ ypXq.jzmj }}㎡</div>
									</div>
									<div class="line" v-if="ypXq.szlc && ypXq.zlc">
										<div class="name">所在楼层/总楼层</div>
										<div class="data">{{ ypXq.szlc }}/{{ ypXq.zlc }}</div>
									</div>
									<div class="line" v-if="ypXq.fwhx">
										<div class="name">房屋户型</div>
										<div class="data">{{ ypXq.fwhx }}</div>
									</div>

									<div class="line" v-if="ypXq.cx">
										<div class="name">朝向</div>
										<div class="data">{{ ypXq.cx }}</div>
									</div>
									<div class="line" v-if="ypXq.wylx">
										<div class="name">物业类型</div>
										<div class="data">{{ ypXq.wylx }}</div>
									</div>
									<div class="line" v-if="ypXq.jcnd">
										<div class="name">建筑年代</div>
										<div class="data">{{ ypXq.jcnd }}年</div>
									</div>
									<div class="line" v-if="ypXq.zxzk">
										<div class="name">户型结构</div>
										<div class="data">{{ ypXq.zxzk }}</div>
									</div>
									<div class="line" v-if="ypXq.jzjg">
										<div class="name">建筑结构</div>
										<div class="data">{{ ypXq.jzjg }}</div>
									</div>
									<div class="line" v-if="ypXq.jzlx">
										<div class="name">建筑类型</div>
										<div class="data">{{ ypXq.jzlx }}</div>
									</div>
								</div>
							</div>
						</div>
						<div class="right">
							<div class="sTitle">地理位置</div>
							<div class="map" id="map2"></div>
						</div>
					</div>
				</div>
				<div class="detailItem">
					<div class="itemTitle">二、参考案例</div>
					<div class="sTitle">挂牌案例</div>
					<div class="hList">
						<div class="item" :key="index" v-for="(item, index) in ckalGpList">
							<img v-if="item.ypImgUrl == ''" class="img" src="../../assets/image/buildingImg.jpg" />
							<img v-else class="img" :src="item.ypImgUrl" />

							<div class="title">{{ item.lpmc }}</div>
							<div class="size">
								<span>{{ item.mj }}m²</span>
								<span>{{ item.hx }}</span>
							</div>
							<div class="time">挂牌时间：{{ item.gpsj }}</div>
							<div class="priceItem sPrice">
								<div class="icon">单</div>
								<div class="price">{{ item.dj }}</div>
								<div class="dw">元/m²</div>
							</div>
							<div class="priceItem aPrice">
								<div class="icon">总</div>
								<div class="price">
									{{ parseFloat((item.dj * item.mj) / 10000).toFixed(0) }}
								</div>
								<div class="dw">万元</div>
							</div>
						</div>
					</div>
					<div class="sTitle">成交案例</div>
					<div class="hList">
						<div class="item" :key="index" v-for="(item, index) in ckalCjList">
							<img v-if="item.ypImgUrl == ''" class="img" src="../../assets/image/buildingImg.jpg" />
							<img v-else class="img" :src="item.ypImgUrl" />
							<div class="title">{{ item.lpmc }}</div>
							<div class="size">
								<span>{{ item.mj }}m²</span>
								<span>{{ item.hx }}</span>
							</div>
							<div class="time">成交时间：{{ item.cjsj }}</div>
							<div class="priceItem sPrice">
								<div class="icon">单</div>
								<div class="price">{{ item.dj }}</div>
								<div class="dw">元/m²</div>
							</div>
							<div class="priceItem aPrice">
								<div class="icon">总</div>
								<div class="price">
									{{ parseFloat((item.dj * item.mj) / 10000).toFixed(0) }}
								</div>
								<div class="dw">万元</div>
							</div>
						</div>
					</div>
				</div>
				<div class="detailItem">
					<div class="itemTitle">三、所属小区</div>
					<div class="contentInfo">
						<div class="left">
							<div class="contentLine">
								<span class="contentTitle" v-if="xqxq.lpmc">小区名称</span>
								<span class="content norwap">
									<Tooltip theme="light" max-width="900" :content="xqxq.lpmc" word-wrap :transfer="true"
										placement="bottom">{{ xqxq.lpmc }}</Tooltip>
								</span>
							</div>
							<div class="contentLine" v-if="xqxq.xszt">
								<span class="contentTitle">销售状态</span>
								<span class="content">
									<Tooltip theme="light" max-width="900" :content="xqxq.xszt" word-wrap :transfer="true"
										placement="bottom">{{ xqxq.xszt }}</Tooltip>
								</span>
							</div>
							<div class="contentLine" v-if="xqxq.xfckj">
								<span class="contentTitle">新房参考价</span>
								<span class="content">
									<Tooltip theme="light" max-width="900" :content="xqxq.xfckj" word-wrap :transfer="true"
										placement="bottom">{{ xqxq.xfckj }}</Tooltip>
								</span>
							</div>
							<div class="contentLine" v-if="xqxq.sldz">
								<span class="contentTitle">小区地址</span>
								<span class="content norwap">
									<Tooltip theme="light" max-width="900" :content="xqxq.sldz" word-wrap :transfer="true"
										placement="bottom">{{ xqxq.sldz }}</Tooltip>
								</span>
							</div>
							<div class="contentLine" v-if="xqxq.qx">
								<span class="contentTitle">区县</span>
								<span class="content">
									<Tooltip theme="light" max-width="900" :content="xqxq.qx" word-wrap :transfer="true"
										placement="bottom">{{ xqxq.qx }}</Tooltip>
								</span>
							</div>
							<div class="contentLine" v-if="xqxq.sq">
								<span class="contentTitle">商圈/板块</span>
								<span class="content">
									<Tooltip theme="light" max-width="900" :content="xqxq.sq" word-wrap :transfer="true"
										placement="bottom">{{ xqxq.sq }}</Tooltip>
								</span>
							</div>
							<div class="contentLine" v-if="xqxq.kfs">
								<span class="contentTitle">开发商</span>
								<span class="content">
									<Tooltip theme="light" max-width="900" :content="xqxq.kfs" word-wrap :transfer="true"
										placement="bottom">{{ xqxq.kfs }}</Tooltip>
								</span>
							</div>
							<div class="contentLine" v-if="xqxq.wylb">
								<span class="contentTitle">物业类型</span>
								<span class="content">
									<Tooltip theme="light" max-width="900" :content="xqxq.wylb" word-wrap :transfer="true"
										placement="bottom">{{ xqxq.wylb }}</Tooltip>
								</span>
							</div>
							<div class="contentLine" v-if="xqxq.cqnx">
								<span class="contentTitle">产权年限</span>
								<span class="content">
									<Tooltip theme="light" max-width="900" :content="xqxq.cqnx" word-wrap :transfer="true"
										placement="bottom">{{ xqxq.cqnx }}</Tooltip>
								</span>
							</div>
							<div class="contentLine" v-if="xqxq.kpsj">
								<span class="contentTitle">开盘时间</span>
								<span class="content">
									<Tooltip theme="light" max-width="900" :content="xqxq.kpsj" word-wrap :transfer="true"
										placement="bottom">{{ xqxq.kpsj }}</Tooltip>
								</span>
							</div>
							<div class="contentLine" v-if="xqxq.jfsj">
								<span class="contentTitle">交房时间</span>
								<span class="content">
									<Tooltip theme="light" max-width="900" :content="xqxq.jfsj" word-wrap :transfer="true"
										placement="bottom">{{ xqxq.jfsj }}</Tooltip>
								</span>
							</div>
							<div class="contentLine" v-if="xqxq.jzlb">
								<span class="contentTitle">建筑类型</span>
								<span class="content">
									<Tooltip theme="light" max-width="900" :content="xqxq.jzlb" word-wrap :transfer="true"
										placement="bottom">{{ xqxq.jzlb }}</Tooltip>
								</span>
							</div>
							<div class="contentLine" v-if="xqxq.lhl">
								<span class="contentTitle">绿化率</span>
								<span class="content">
									<Tooltip theme="light" max-width="900" :content="xqxq.lhl" word-wrap :transfer="true"
										placement="bottom">{{ xqxq.lhl }}</Tooltip>
								</span>
							</div>
							<div class="contentLine" v-if="xqxq.zdmj">
								<span class="contentTitle">占地面积</span>
								<span class="content">
									<Tooltip theme="light" max-width="900" :content="xqxq.zdmj" word-wrap :transfer="true"
										placement="bottom">{{ xqxq.zdmj }}</Tooltip>
								</span>
							</div>
							<div class="contentLine" v-if="xqxq.jzmj">
								<span class="contentTitle">建筑面积</span>
								<span class="content">
									<Tooltip theme="light" max-width="900" :content="xqxq.jzmj" word-wrap :transfer="true"
										placement="bottom">{{ xqxq.jzmj }}</Tooltip>
								</span>
							</div>
							<div class="contentLine" v-if="xqxq.rjl">
								<span class="contentTitle">容积率</span>
								<span class="content">
									<Tooltip theme="light" max-width="900" :content="xqxq.rjl" word-wrap :transfer="true"
										placement="bottom">{{ xqxq.rjl }}</Tooltip>
								</span>
							</div>
							<div class="contentLine" v-if="xqxq.ldzs">
								<span class="contentTitle">楼栋总数</span>
								<span class="content">
									<Tooltip theme="light" max-width="900" :content="xqxq.ldzs" word-wrap :transfer="true"
										placement="bottom">{{ xqxq.ldzs }}</Tooltip>
								</span>
							</div>
							<div class="contentLine" v-if="xqxq.zhs">
								<span class="contentTitle">总户数</span>
								<span class="content">
									<Tooltip theme="light" max-width="900" :content="xqxq.zhs" word-wrap :transfer="true"
										placement="bottom">{{ xqxq.zhs }}</Tooltip>
								</span>
							</div>
							<div class="contentLine" v-if="xqxq.zxqk">
								<span class="contentTitle">装修情况</span>
								<span class="content">
									<Tooltip theme="light" max-width="900" :content="xqxq.zxqk" word-wrap :transfer="true"
										placement="bottom">{{ xqxq.zxqk }}</Tooltip>
								</span>
							</div>
							<div class="contentLine" v-if="xqxq.hxwz">
								<span class="contentTitle">户型</span>
								<span class="content">
									<Tooltip theme="light" max-width="900" :content="xqxq.hxwz" word-wrap :transfer="true"
										placement="bottom">{{ xqxq.hxwz }}</Tooltip>
								</span>
							</div>
							<div class="contentLine" v-if="xqxq.cws">
								<span class="contentTitle">车位数</span>
								<span class="content">
									<Tooltip theme="light" max-width="900" :content="xqxq.cws" word-wrap :transfer="true"
										placement="bottom">{{ xqxq.cws }}</Tooltip>
								</span>
							</div>
							<div class="contentLine" v-if="xqxq.cwpp">
								<span class="contentTitle">车位配比</span>
								<span class="content">
									<Tooltip theme="light" max-width="900" :content="xqxq.cwpp" word-wrap :transfer="true"
										placement="bottom">{{ xqxq.cwpp }}</Tooltip>
								</span>
							</div>
							<div class="contentLine" v-if="xqxq.wygs">
								<span class="contentTitle">物业公司</span>
								<span class="content norwap">
									<Tooltip theme="light" max-width="900" :content="xqxq.wygs" word-wrap :transfer="true"
										placement="bottom">{{ xqxq.wygs }}</Tooltip>
								</span>
							</div>
							<div class="contentLine" v-if="xqxq.wyf">
								<span class="contentTitle">物业费</span>
								<span class="content norwap">
									<Tooltip theme="light" max-width="900" :content="xqxq.wyf" word-wrap :transfer="true"
										placement="bottom">{{ xqxq.wyf }}</Tooltip>
								</span>
							</div>
						</div>
						<div class="right">
							<div class="sTitle">价格走势</div>
							<div id="chart2"></div>
						</div>
					</div>
				</div>

				<div class="detailItem">
					<div class="itemTitle">四、周边</div>
					<div class="sTitle">周边小区</div>
					<div class="hList">
						<div class="item" :key="index" v-for="(item, index) in ckalList">
							<img v-if="item.ypImgUrl == ''" class="img" src="../../assets/image/buildingImg.jpg" />
							<img v-else class="img" :src="item.ypImgUrl" />
							<div class="title">{{ item.lpmc }}</div>
							<div class="address">
								<svg-icon class="mapIcon" iconClass="map"></svg-icon>
								<span>{{ item.juli }}米</span>
							</div>
							<!-- <div class="priceItem sPrice">
              <div class="icon">单</div>
              <div class="price">{{item.dj||0.00}}</div>
              <div class="dw">元/m²</div>
              </div>-->
							<div class="priceItem aPrice" style="top: 14px">
								<div class="icon">均</div>
								<div class="price">{{ item.dj || "--" }}</div>
								<div class="dw">元/m²</div>
							</div>
						</div>
					</div>
					<div class="sTitle">周边配套</div>
					<div class="hLine">
						<div class="htitle">交通</div>
						<div class="hitemList">
							<div class="item" :key="index" v-for="(item, index) in tabData['交通']" v-show="index < 20">
								<span class="name">{{ item.title }}</span>
								<span class="length">[{{ item.juli }}米]</span>
								<span class="type" v-if="item.tag" v-html="item.tag"></span>
							</div>
						</div>
					</div>
					<div class="hLine">
						<div class="htitle">教育</div>
						<div class="hitemList">
							<div class="item" :key="index" v-for="(item, index) in tabData['教育']" v-show="index < 20">
								<span class="name">{{ item.title }}</span>
								<span class="length">[{{ item.juli }}米]</span>
								<span class="type" v-if="item.tag" v-html="item.tag"></span>
							</div>
						</div>
					</div>
					<div class="hLine">
						<div class="htitle">医疗</div>
						<div class="hitemList">
							<div class="item" :key="index" v-for="(item, index) in tabData['医疗']" v-show="index < 20">
								<span class="name">{{ item.title }}</span>
								<span class="length">[{{ item.juli }}米]</span>
								<span class="type" v-if="item.tag" v-html="item.tag"></span>
							</div>
						</div>
					</div>
					<div class="hLine">
						<div class="htitle">商业</div>
						<div class="hitemList">
							<div class="item" :key="index" v-for="(item, index) in tabData['商业']" v-show="index < 20">
								<span class="name">{{ item.title }}</span>
								<span class="length">[{{ item.juli }}米]</span>
								<span class="type" v-if="item.tag" v-html="item.tag"></span>
							</div>
						</div>
					</div>
					<div class="hLine">
						<div class="htitle">特殊</div>
						<div class="hitemList">
							<div class="item" :key="index" v-for="(item, index) in tabData['厌恶']" v-show="index < 20">
								<span class="name">{{ item.title }}</span>
								<span class="length">[{{ item.juli }}米]</span>
								<span class="type" v-if="item.tag" v-html="item.tag"></span>
							</div>
						</div>
					</div>
				</div>
				<div class="detailItem" v-show="false">
					<div class="itemTitle">五、土地市场</div>
					<Table border tooltip-theme="light" :columns="td.key" :data="td.data"></Table>
					<div class="chartItem" v-show="false" id="chart5"></div>
				</div>
				<div class="detailItem" v-show="false">
					<div class="itemTitle">六、商品房市场</div>
					<Table border tooltip-theme="light" :columns="spf.key" :data="spf.data"></Table>
					<div class="chartItem" v-show="false" id="chart6"></div>
				</div>
				<div class="detailItem" v-show="false">
					<div class="itemTitle">七、司法拍卖</div>
					<Table border tooltip-theme="light" :columns="sfpm.key" :data="sfpm.data"></Table>
					<div class="chartItem" v-show="false" id="chart7"></div>
				</div>
				<div class="detailItem" v-show="false">
					<div class="itemTitle">八、产权交易所/第三方交易所</div>
					<Table border tooltip-theme="light" :columns="cqjys.key" :data="cqjys.data"></Table>
					<div class="chartItem" v-show="false" id="chart8"></div>
				</div>
			</div>
		</div>
		<Modal :closable="false" v-model="infoModal" @on-ok="hideModal" @on-cancel="hideModal"
			class-name="vertical-center-modal" title="样本选取和估价结果说明" :mask-closable="false">
			<div class="document">
				<div>
					<div class="document">
						<p class="paragraph text-align-type-left pap-line-1.3 pap-line-rule-auto pap-spacing-before-3pt pap-spacing-after-3pt"
							style="text-indent: 1em">
							只选取估价基准日之前半年内的成交或挂牌数据作为样本数据。假设证载地址所属小区符合条件的样本数据样本量为n，证载地址所属小区周边5公里内的小区样本数据样本量为m；进行模型训练的样本量为N；估价训练样本数据以外的数据进行估价，准确度为A；同等条件下，对相同房屋进行估价的稳定性为T。有如下3种情况：
						</p>
						<p
							class="paragraph text-align-type-left pap-line-1.3 pap-line-rule-auto pap-spacing-before-3pt pap-spacing-after-3pt">
							1、n&gt;=30时，选取所属小区样本数据，即N=n。该条件下，估价结果准确度和稳定性都较高，A&gt;=95%，T&gt;98%；
						</p>
						<p
							class="paragraph text-align-type-left pap-line-1.3 pap-line-rule-auto pap-spacing-before-3pt pap-spacing-after-3pt">
							2、0&lt;n&lt;30时，周边5公里内小区的数据也作为样本数据，即N=n+m。<span>当N&gt;=30时，<span
									style="color: #e53333">该条件下，估价结果准确度和稳定性都较高，A&gt;=95%，T&gt;98%；当N&lt;30时，该条件下，估价结果准确度和稳定性都较高</span></span><span
								style="color: #e53333">；</span>
						</p>
						<p
							class="paragraph text-align-type-left pap-line-1.3 pap-line-rule-auto pap-spacing-before-3pt pap-spacing-after-3pt">
							<span style="background-color: "></span>3、n=0时，周边5公里内小区的数据作为样本数据，即N=m。当N&gt;=30时，<span
								style="color: #e53333">该条件下，估价结果准确度和稳定性都较高，A&gt;=95%，T&gt;98%；当N&lt;30时，该条件下，估价结果准确度和稳定性都较高。</span><span
								style="background-color: "><span style="color: #e53333"></span></span>
						</p>
						<p
							class="paragraph text-align-type-left pap-line-1.3 pap-line-rule-auto pap-spacing-before-3pt pap-spacing-after-3pt">
							注：房屋价值估价和租金估价的样本选取和估价结果说明相同。
						</p>
					</div>
				</div>
			</div>
			<div class="modal-footer" style="display: flex; justify-content: center" slot="footer">
				<Button type="primary" @click="hideModal">确定</Button>
			</div>
		</Modal>
	</div>
</template>

<script>
import tableBox from "@/components/tableBox";
import BMap from "BMap";
import BMapLib from "BMapLib";
import html2canvas from "html2canvas";
import jsPdf from "jspdf";
import {
	rwlb,
	one_yp,
	yp_cjal,
	xqyp_list,
	yp_td,
	yp_spf,
	yp_sfpm,
	yp_zxq,
	yp_tdtj,
	yp_wylx,
	yp_cqjys,
	yp_xq,
	yp_history,
	yp_ynpg,
	recordByTaskId,
	yp_gpal,
	yp_zbxq,
	yp_xqxq,
	yp_jgzs,
} from "@/api/pledge";
import {
	formatDate
} from "@/utils";
import {
	getLandUse2
} from "@/api/public";

import dataReports from "@/mixins/dataReports";

export default {
	name: "result",
	mixins: [dataReports],
	components: {
		tableBox,
	},
	data() {
		return {
			searchWidth: "1",
			searchItem: null,

			params: {
				current: 1,
				size: 10,
				taskId: "",
				zzdz: "",
				state: 1,
				resultId: "",
			},
			// 地图标注icon图片
			liveIcon: require("@/assets/image/map/live.png"),
			liveIconActive: require("@/assets/image/map/live.png"),
			closeIcon: require("@/assets/image/map/close.png"),
			overlayCloseIcon: require("@/assets/image/map/overLay_close.svg"),

			tabs: [{
				id: 0,
				name: "参考案例",
			},
			{
				id: 1,
				name: "周边小区",
			},
			{
				id: 2,
				name: "周边配套",
			},
			{
				id: 3,
				name: "小区详情",
			},
			{
				id: 4,
				name: "价格走势",
			},
			],
			activeTab: 0,

			columns1: [{
				title: "Name",
				key: "name",
			},
			{
				title: "Age",
				key: "age",
			},
			{
				title: "Address",
				key: "address",
			},
			],
			mapData: {
				zoom: 12,
				center: {
					lng: 116.46,
					lat: 39.92,
				},
			},
			data1: [],
			tab: "",
			tab2: "tab2",
			activeOtherTab: 2,
			tableOption1: [{
				name: "格式调整",
				optionList: [{
					value: "New York",
					label: "New York",
				},],
			},],
			tableOption2: [{
				name: "土地用途",
				multiple: true,
				value: [],
				optionList: [],
			},
			{
				name: "周期",
				optionList: [{
					value: 1,
					label: "月",
				},
				{
					value: 3,
					label: "年",
				},
				],
			},
			],
			tableOption3: [{
				name: "物业类型",
				multiple: true,
				optionList: [],
			},
			{
				name: "行政区",
				multiple: true,
				optionList: [],
			},
			{
				name: "周期",
				value: ["月"],
				optionList: [{
					value: 1,
					label: "月",
				},
				{
					value: 3,
					label: "年",
				},
				],
			},
			],
			mapTab: 2,
			map: null,
			option: {
				tooltip: {
					trigger: "axis",
					axisPointer: {
						// 坐标轴指示器，坐标轴触发有效
						type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
					},
				},
				legend: {
					top: "14px",
					left: "40px",
					itemWidth: 12,
					itemHeight: 12,
					data: ["面积", "成交宗数", "楼面值"],
				},
				grid: {
					left: "3%",
					right: "4%",
					bottom: "3%",
					containLabel: true,
				},
				xAxis: {
					type: "value",
					axisLabel: {
						textStyle: {
							color: "#666",
						},
					},
					axisLine: {
						show: true,
						lineStyle: {
							color: "#D6D7D9",
						},
					},
				},
				yAxis: {
					axisLabel: {
						textStyle: {
							color: "#666",
						},
					},
					type: "category",
					data: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
					axisLine: {
						show: true,
						lineStyle: {
							color: "#D6D7D9",
						},
					},
				},
				color: ["#5C89FF", "#7C6AF2", "#C95FF2"],
				series: [{
					name: "面积",
					type: "bar",
					stack: "总量",
					label: {
						normal: {
							show: true,
							position: "insideRight",
						},
					},
					ckalList: [],
					ynpg: {
						key: [
							// {
							//     title: "委托估价方",
							//     align: "center",
							//     key: "wtf",
							// },
							{
								title: "估价基准日",
								align: "center",
								key: "initialEvalDate",
								render: (h, params) => {
									return h(
										"div",
										this.formatDate(params.row.initialEvalDate)
									);
								},
							},
							{
								title: "证载地址",
								key: "zzdz",
								align: "center",
								width: 300,
								tooltip: true,

								ellipsis: true,
								render: (h, params) => {
									return h(
										"div", {
										attrs: {
											class: "btn-detail",
										},
										on: {
											click: () => {
												const routeUrl = this.$router.resolve({
													path: "/Empowerment/pledge/pledgeManage/result",
													query: {
														taskId: this.taskEntity.id,
														zzdz: params.row.zzdz,
														resultId: params.row.result
															.id,
													},
												});

												window.open(routeUrl.href, "_blank");
											},
										},
									},
										params.row.zzdz
									);
								},
							},
							{
								title: "(元/㎡)",
								align: "center",
								key: "pgdj",
								renderHeader: (h, index) => {
									return h("div", [
										h("div", "单价"),
										h("div", "(元/㎡)"),
									]);
								},
							},
							{
								title: "总价（万元）",
								align: "center",
								key: "pgzj",
								render: (h, params) => {
									return h(
										"div",
										params.row.pgzj == "" ? "" : params.row.pgzj / 10000
									);
								},
								renderHeader: (h, index) => {
									return h("div", [
										h("div", "总价"),
										h("div", "（万元）"),
									]);
								},
							},
						],
						data: [],
					},
					data: [120, 132, 101, 134, 90, 230, 210],
				},
				{
					name: "楼面值",
					type: "bar",
					stack: "总量",
					label: {
						normal: {
							show: true,
							position: "insideRight",
						},
					},
					data: [220, 182, 191, 234, 290, 330, 310],
				},
				],
			},
			showDetail: false,
			option2: {
				title: {
					text: "房地产开发投资",
					top: "25px",
					left: "center",
					textStyle: {
						color: "#666",
						fontSize: 12,
					},
				},
				tooltip: {
					trigger: "axis",
					axisPointer: {
						type: "shadow",
						label: {
							show: true,
						},
					},
					position: ["25%", "25%"],
					formatter: (params) => {
						let tips = "";
						params.forEach((item, index) => {
							var unit = "亿元";
							tips += item.seriesName + "：" + item.value + unit + "<br/>";
						});
						return tips;
					},
				},
				graphic: {
					type: "text",
					z: 100,
					left: "10%",
					top: "52px",
					style: {
						fill: "#666",
						fontSize: 12,
						text: "亿元",
					},
				},
				color: ["#5B8FF9", "#5AD8A6", "#5D7092"],
				legend: {
					top: "14px",
					left: "40px",
					itemWidth: 12,
					itemHeight: 12,
					itemGap: 5,
					textStyle: {
						color: "#666",
						fontSize: 12,
					},
					data: ["住宅", "办公", "商业", "其他"],
				},
				grid: {
					left: "3%",
					right: "4%",
					bottom: "3%",
					containLabel: true,
				},
				yAxis: [{
					// name: "亿",
					type: "value",
					nameTextStyle: {
						color: "#666",
					},
					axisTick: {
						show: false,
					},
					splitLine: {
						lineStyle: {
							type: "dashed",
							color: "#D6D7D9",
						},
					},
					axisLabel: {
						inside: false,
						textStyle: {
							color: "#666",
							fontSize: 12,
						},
						formatter: "{value} ",
					},
					axisLine: {
						symbol: ["none", "arrow"],
						symbolSize: [5, 10],
						lineStyle: {
							color: "#D6D7D9",
							fontSize: 12,
						},
					},
				},],
				xAxis: [{
					type: "category",
					axisTick: {
						show: false,
					},
					axisLine: {
						show: true,
						lineStyle: {
							color: "#D6D7D9",
						},
					},
					axisLabel: {
						color: "#666",
						fontSize: 12,
						formatter: "{value} ",
					},
					data: ["住宅", "办公", "商业", "其他"],
				},],
				series: [{
					name: "住宅",
					type: "bar",
					stack: "总量",
					barWidth: "46px",
					data: [320, 302, 301, 334, 390, 330, 320],
				},
				{
					name: "办公",
					type: "bar",
					stack: "总量",
					data: [320, 302, 301, 334, 390, 330, 320],
				},
				{
					name: "商业",
					type: "bar",
					stack: "总量",
					data: [320, 302, 301, 334, 390, 330, 320],
				},
				{
					name: "其他",
					type: "bar",
					stack: "总量",
					data: [320, 302, 301, 334, 390, 330, 320],
				},
				],
			},
			option3: {
				tooltip: {
					trigger: "axis",
				},
				legend: {
					top: "14px",
					left: "0px",
					itemWidth: 12,
					itemHeight: 12,
					itemGap: 5,
					textStyle: {
						color: "#666",
						fontSize: 12,
					},
					data: ["金碧世纪花园", "黄埔", "广州"],
				},
				color: ["#5AD8A6", "#5B8FF9", "#5D7092"],
				grid: {
					left: "0%",
					right: "4%",
					bottom: "3%",
					containLabel: true,
				},
				xAxis: {
					type: "category",
					boundaryGap: false,
					data: [],
					axisLabel: {
						textStyle: {
							color: "#666",
						},
					},
					axisLine: {
						show: true,
						lineStyle: {
							color: "#D6D7D9",
						},
					},
				},
				yAxis: {
					type: "value",
					axisLabel: {
						textStyle: {
							color: "#666",
						},
					},
					axisLine: {
						show: true,
						lineStyle: {
							color: "#D6D7D9",
						},
					},
				},
				series: [{
					name: "金碧世纪花园",
					type: "line",
					stack: "总量",
					data: [120, 132, 101, 134, 90, 230, 210],
					lineStyle: {
						color: "#253A5D", //改变折线颜色
					},
				},
				{
					name: "黄埔",
					type: "line",
					stack: "总量",
					data: [220, 182, 191, 234, 290, 330, 310],
					lineStyle: {
						color: "#253A5D", //改变折线颜色
					},
				},
				{
					name: "广州",
					type: "line",
					stack: "总量",
					data: [150, 232, 201, 154, 190, 330, 410],
					lineStyle: {
						color: "#253A5D", //改变折线颜色
					},
				},
				],
			},
			landColumns: [{
				title: "序号",
				align: "center",
				width: 150,
				key: "xh",
			},
			{
				title: "各类型土地出让宗数",
				align: "center",
				width: 150,
				key: "zs",
			},
			{
				title: "出让面积（m²）",
				align: "center",
				width: 150,
				key: "crmj",
				renderHeader: (h, index) => {
					return h("div", [h("div", "出让面积"), h("div", "（m²）")]);
				},
			},
			{
				title: "成交宗数",
				align: "center",
				width: 150,
				key: "cjzs",
			},
			{
				title: "成交面积（m²）",
				align: "center",
				width: 150,
				key: "cjmj",
				renderHeader: (h, index) => {
					return h("div", [h("div", "成交面积"), h("div", "（m²）")]);
				},
			},
			{
				title: "成交建面（m²）",
				align: "center",
				width: 150,
				key: "cjmj",
				renderHeader: (h, index) => {
					return h("div", [h("div", "成交建面"), h("div", "（m²）")]);
				},
			},
			{
				title: "楼面价（元/m²）",
				align: "center",
				width: 150,
				key: "lmj",
				renderHeader: (h, index) => {
					return h("div", [h("div", "楼面价"), h("div", "（元/m²）")]);
				},
			},
			{
				title: "起始价（元/m²）",
				align: "center",
				width: 150,
				key: "qsj",
				renderHeader: (h, index) => {
					return h("div", [h("div", "起始价"), h("div", "（元/m²）")]);
				},
			},
			{
				title: "成交价（元/m²）",
				align: "center",
				width: 150,
				key: "cjj",
				renderHeader: (h, index) => {
					return h("div", [h("div", "成交价"), h("div", "（元/m²）")]);
				},
			},
			{
				title: "溢价率",
				align: "center",
				width: 150,
				key: "yjl",
			},
			{
				title: "同比",
				align: "center",
				width: 150,
				key: "tb",
			},
			{
				title: "环比",
				align: "center",
				width: 150,
				key: "hb",
			},
			],
			landData: [],
			goodsColumns: [{
				title: "时间",
				align: "center",
				width: 150,
				key: "sj",
			},
			{
				title: "地区",
				align: "center",
				width: 150,
				key: "dq",
			},
			{
				title: "物业类型",
				align: "center",
				width: 150,
				key: "wylx",
			},
			{
				title: "成交套数",
				align: "center",
				width: 150,
				key: "cjts",
			},
			{
				title: "成交面积（m²）",
				align: "center",
				width: 150,
				key: "cjmj",
				renderHeader: (h, index) => {
					return h("div", [h("div", "成交面积"), h("div", "（m²）")]);
				},
			},
			{
				title: "成交均价（万元）",
				align: "center",
				width: 150,
				key: "cjjj",
				render: (h, params) => {
					return h(
						"div",
						params.row.cjjj == "" ? "" : (params.row.cjjj / 10000).toFixed(0)
					);
				},
				renderHeader: (h, index) => {
					return h("div", [h("div", "成交均价"), h("div", "（万元）")]);
				},
			},
			{
				title: "成交金额（万元）",
				align: "center",
				width: 150,
				key: "cjje",
				render: (h, params) => {
					return h(
						"div",
						params.row.cjje == "" ? "" : (params.row.cjje / 10000).toFixed(0)
					);
				},
				renderHeader: (h, index) => {
					return h("div", [h("div", "成交金额"), h("div", "（万元）")]);
				},
			},
			{
				title: "供应套数",
				align: "center",
				width: 150,
				key: "gyts",
			},
			{
				title: "供应面积（m²）",
				align: "center",
				width: 150,
				key: "gymj",
				renderHeader: (h, index) => {
					return h("div", [h("div", "供应面积"), h("div", "（m²）")]);
				},
			},
			{
				title: "库存套数",
				align: "center",
				width: 150,
				key: "kcts",
			},
			{
				title: "库存面积（m²）",
				align: "center",
				width: 150,
				key: "kcmj",
				renderHeader: (h, index) => {
					return h("div", [h("div", "库存面积"), h("div", "（m²）")]);
				},
			},
			{
				title: "供求比",
				align: "center",
				width: 150,
				key: "gqb",
			},
			{
				title: "去化周期",
				align: "center",
				key: "qhzq",
			},
			],
			goodsData: [],
			spfLoding: false,
			judicialColumns: [{
				align: "center",
				title: "楼盘名称",
				width: 150,
				key: "lpmc",
			},
			{
				title: "成交时间",
				align: "center",
				width: 150,
				key: "cjsj",
			},
			{
				title: "面积（m²）",
				align: "center",
				width: 150,
				key: "mj",
				renderHeader: (h, index) => {
					return h("div", [h("div", "面积"), h("div", "（m²）")]);
				},
			},
			{
				title: "居室类型",
				align: "center",
				width: 150,
				key: "jslx",
			},
			{
				align: "center",
				title: "单价（元/m²）",
				width: 150,
				key: "dz",
				renderHeader: (h, index) => {
					return h("div", [h("div", "单价"), h("div", "（元/m²）")]);
				},
			},
			{
				title: "总价（万元）",
				align: "center",
				key: "zj",
				render: (h, params) => {
					return h("div", params.row.zj == "" ? "" : params.row.zj / 10000);
				},
				renderHeader: (h, index) => {
					return h("div", [h("div", "总价"), h("div", "（万元）")]);
				},
			},
			],
			judicialData: [],
			powerColumns: [{
				title: "楼盘名称",
				align: "center",
				width: 150,
				key: "pjmc",
			},
			{
				title: "成交时间",
				align: "center",
				width: 150,
				key: "cjsj",
			},
			{
				title: "面积（m²）",
				align: "center",
				width: 150,
				key: "mj",
				renderHeader: (h, index) => {
					return h("div", [h("div", "面积"), h("div", "（m²）")]);
				},
			},
			{
				title: "居室类型",
				align: "center",
				width: 150,
				key: "jslx",
			},
			{
				title: "单价（元/m²）",
				align: "center",
				width: 150,
				key: "dj",
				renderHeader: (h, index) => {
					return h("div", [h("div", "单价"), h("div", "（元/m²）")]);
				},
			},
			{
				title: "总价（万元）",
				align: "center",
				key: "zj",
				render: (h, params) => {
					return h("div", params.row.zj == "" ? "" : params.row.zj / 10000);
				},
				renderHeader: (h, index) => {
					return h("div", [h("div", "总价"), h("div", "（万元）")]);
				},
			},
			],
			powerData: [],
			referenceColumns: [{
				title: "序号",
				align: "center",
				width: 150,
				key: "xh",
			},
			{
				title: "证载地址",
				align: "center",
				key: "zzdz",
				width: 300,
				tooltip: true,

				ellipsis: true,
			},
			{
				title: "案例",
				align: "center",
				width: 150,
				key: "al",
			},
			{
				title: "小区名称",
				align: "center",
				width: 150,
				key: "xqmc",
			},
			{
				title: "成交时间",
				align: "center",
				width: 150,
				key: "cjsj",
			},
			{
				title: "建筑面积（万m²）",
				align: "center",
				width: 150,
				key: "jzmj",
				renderHeader: (h, index) => {
					return h("div", [h("div", "建筑面积"), h("div", "（万m²）")]);
				},
			},
			{
				title: "房屋户型",
				align: "center",
				width: 150,
				key: "fwhx",
			},
			{
				title: "成交总价（万元）",
				align: "center",
				key: "cjzj",
				render: (h, params) => {
					return h(
						"div",
						params.row.cjzj == "" ? "" : params.row.cjzj / 10000
					);
				},
				renderHeader: (h, index) => {
					return h("div", [h("div", "成交总价"), h("div", "（万元）")]);
				},
			},
			],
			searchList: [],
			searchValue: "",
			searchTaskId: "",
			searchResultId: "",
			searchLoading: false,
			localSearch: null,
			nearbySearch: null,
			ckalCjList: [],
			ckalGpList: [],
			myChart: "",
			chart5: "",
			chart6: "",
			chart7: "",
			chart8: "",
			chart9: "",
			searchListData: [],
			yp: {},
			td: {
				key: [{
					title: "时间",
					align: "center",
					width: 150,
					tooltip: true,
					key: "landTime",
				},
				{
					title: "城市",
					align: "center",
					width: 150,
					tooltip: true,
					key: "provinceName",
				},
				{
					title: "土地用途",
					align: "center",
					width: 150,
					tooltip: true,
					key: "landUseName",
				},
				{
					title: "成交宗数",
					align: "center",
					width: 150,
					tooltip: true,
					key: "religion",
				},
				{
					title: "成交面积（㎡）",
					align: "center",
					tooltip: true,
					width: 150,
					key: "bargainArea",
					render: (h, params) => {
						return h("div", params.row.religion ? params.row.bargainArea.toFixed(0) : '');
					},
					renderHeader: (h, index) => {
						return h("div", [h("div", "成交面积"), h("div", "（m²）")]);
					},
				},
				{
					title: "成交面积同比",
					align: "center",
					tooltip: true,
					width: 150,
					render: (h, params) => {
						return h(
							"div",
							params.row.religion ? (params.row.bargainAreaWith * 100).toFixed(2) + "%" :
								''
						);
					},
					key: "bargainAreaWith",
				},
				{
					title: "成交建面（㎡）",
					align: "center",
					tooltip: true,
					width: 150,
					key: "bargainBuild",
					render: (h, params) => {
						return h("div", params.row.religion ? params.row.bargainBuild.toFixed(0) : '');
					},
					renderHeader: (h, index) => {
						return h("div", [h("div", "成交建面"), h("div", "（m²）")]);
					},
				},
				{
					title: "成交建面同比",
					align: "center",
					tooltip: true,
					width: 150,
					render: (h, params) => {
						return h(
							"div",
							params.row.religion ? (params.row.bargainBuildWith * 100).toFixed(2) +
								"%" : ''
						);
					},
					key: "bargainBuildWith",
				},
				{
					title: "起始价（万元）",
					align: "center",
					tooltip: true,
					width: 150,
					key: "startPrice",
					render: (h, params) => {
						return h(
							"div",
							params.row.religion ? params.row.startPrice == "" ?
								"" :
								(params.row.startPrice / 10000).toFixed(0) : ''
						);
					},
					renderHeader: (h, index) => {
						return h("div", [h("div", "起始价"), h("div", "（万元）")]);
					},
				},
				{
					title: "起始价同比",
					align: "center",
					tooltip: true,
					width: 150,
					render: (h, params) => {
						return h(
							"div",
							params.row.religion ? (params.row.startPriceWith * 100).toFixed(2) + "%" :
								''
						);
					},
					key: "startPriceWith",
				},
				{
					title: "成交价",
					align: "center",
					tooltip: true,
					width: 150,
					render: (h, params) => {
						return h(
							"div",
							params.row.religion ? params.row.transactionPrice == "" ?
								"" :
								(params.row.transactionPrice / 10000).toFixed(0) : ''
						);
					},
					renderHeader: (h, index) => {
						return h("div", [h("div", "成交价"), h("div", "（万元）")]);
					},
					key: "transactionPrice",
				},
				{
					title: "成交价同比",
					align: "center",
					tooltip: true,
					width: 150,
					render: (h, params) => {
						return h(
							"div",
							params.row.religion ? (params.row.transactionPriceWith * 100).toFixed(2) +
								"%" : ''
						);
					},
					key: "transactionPriceWith",
				},
				{
					title: "楼面价（元/㎡）",
					align: "center",
					tooltip: true,
					width: 150,
					key: "floorPrice",
					render: (h, params) => {
						return h("div", params.row.religion ? params.row.floorPrice.toFixed(0) : '');
					},
					renderHeader: (h, index) => {
						return h("div", [h("div", "楼面价"), h("div", "（元/㎡）")]);
					},
				},
				{
					title: "楼面价同比",
					align: "center",
					tooltip: true,
					width: 150,
					render: (h, params) => {
						return h(
							"div",
							params.row.religion ? (params.row.floorPriceWith * 100).toFixed(2) + "%" :
								''
						);
					},
					key: "floorPriceWith",
				},
				{
					title: "溢价率",
					align: "center",
					tooltip: true,
					width: 150,
					render: (h, params) => {
						return h("div", params.row.religion ? (params.row.premiumRate * 100).toFixed(2) +
							"%" : '');
					},
					key: "premiumRate",
				},
				],
				data: [],
			},
			sfpm: {
				key: [{
					title: "楼盘名称",
					align: "center",
					tooltip: true,
					width: 150,
					key: "xqmc",
				},
				{
					title: "成交时间",
					align: "center",
					tooltip: true,
					width: 150,
					key: "cjsj",
				},
				{
					title: "面积（㎡）",
					align: "center",
					tooltip: true,
					width: 150,
					key: "mj",
					renderHeader: (h, index) => {
						return h("div", [h("div", "面积"), h("div", "（m²）")]);
					},
				},
				{
					title: "居室类型",
					align: "center",
					tooltip: true,
					width: 150,
					key: "jslx",
				},
				{
					title: "单价（元/㎡）",
					align: "center",
					tooltip: true,
					width: 150,
					key: "dj",
					renderHeader: (h, index) => {
						return h("div", [h("div", "单价"), h("div", "（元/㎡）")]);
					},
				},
				{
					title: "总价(万元)",
					align: "center",
					key: "zj",
					render: (h, params) => {
						return h("div", params.row.zj == "" ? "" : params.row.zj / 10000);
					},
					renderHeader: (h, index) => {
						return h("div", [h("div", "总价"), h("div", "(万元)")]);
					},
				},
				],
				data: [],
				total: 0,
			},
			cqjys: {
				key: [{
					title: "楼盘名称",
					align: "center",
					width: 150,
					key: "xqmc",
				},
				{
					title: "成交时间",
					align: "center",
					width: 150,
					key: "cjsj",
				},
				{
					title: "面积（㎡）",
					align: "center",
					width: 150,
					key: "mj",
					renderHeader: (h, index) => {
						return h("div", [h("div", "面积"), h("div", "（m²）")]);
					},
				},
				{
					title: "居室类型",
					align: "center",
					width: 150,
					key: "jslx",
				},
				{
					title: "单价（元/㎡）",
					align: "center",
					width: 150,
					key: "dj",
					renderHeader: (h, index) => {
						return h("div", [h("div", "单价"), h("div", "（元/㎡）")]);
					},
				},
				{
					title: "总价(万元)",
					align: "center",
					key: "zj",
					render: (h, params) => {
						return h("div", params.row.zj == "" ? "" : params.row.zj / 10000);
					},
					renderHeader: (h, index) => {
						return h("div", [h("div", "总价"), h("div", "(万元)")]);
					},
				},
				],
				data: [],
				total: 0,
			},
			xqyp: {
				key: [{
					title: "序号",
					align: "center",
					width: 150,
					type: "index",
				},

				{
					title: "证载地址",
					key: "zzdz",
					align: "center",
					width: 300,
					ellipsis: true,
					tooltip: true,

					render: (h, params) => {
						return h(
							"a", {
							attrs: {
								class: "btn-detail",
							},
							on: {
								click: () => {
									const routeUrl = this.$router.resolve({
										path: "/Empowerment/pledge/pledgeManage/result",
										query: {
											taskId: params.row.result.taskId,
											zzdz: params.row.zzdz,
											resultId: params.row.result.id,
										},
									});

									window.open(routeUrl.href, "_blank");
								},
							},
						},
							[
								h(
									"Tooltip", {
									props: {
										transfer: true,
										theme: "light",
									},
								},
									[
										params.row.zzdz, //控制树形显示的内容
										h(
											"span", {
											slot: "content",
											style: {
												whiteSpace: "normal",
											},
										},
											params.row.zzdz //控制Tooltip显示的内容
										),
									]
								),
								h("span", {
									style: {
										display: "inline-block",
										float: "right",
										marginRight: "32px",
									},
								}),
							]
						);
					},
				},
				{
					title: "建筑面积(㎡)",
					align: "center",
					width: 150,
					key: "jzmj",
					renderHeader: (h, index) => {
						return h("div", [h("div", "建筑面积"), h("div", "（m²）")]);
					},
				},
				{
					title: "估价基准日",
					align: "center",
					width: 150,
					key: "evalTime",
					render: (h, params) => {
						return h(
							"div",
							params.row.result.pgsj && formatDate(params.row.result.pgsj)
						);
					},
				},
				{
					title: "单价（元/㎡）",
					width: 150,
					align: "center",
					render: (h, params) => {
						return h("div", params.row.result.pgdj);
					},
					renderHeader: (h, index) => {
						return h("div", [h("div", "单价"), h("div", "（元/㎡）")]);
					},
				},
				{
					title: "总价（万元）",
					width: 150,
					align: "center",
					render: (h, params) => {
						return h(
							"div",
							params.row.result.pgzj == "" ?
								"" :
								params.row.result.pgzj / 10000
						);
					},
					renderHeader: (h, index) => {
						return h("div", [h("div", "总价"), h("div", "（万元）")]);
					},
				},
				{
					title: "调整后单价（元/㎡）",
					width: 150,
					align: "center",
					render: (h, params) => {
						return h("div", params.row.result.fjxzhzDj);
					},
					renderHeader: (h, index) => {
						return h("div", [
							h("div", "调整后单价"),
							h("div", "（元/㎡）"),
						]);
					},
				},
				{
					title: "调整后总价（万元）",
					width: 150,
					align: "center",
					render: (h, params) => {
						return h(
							"div",
							params.row.result.fjxzhzZj == "" ?
								"" :
								params.row.result.fjxzhzZj / 10000
						);
					},
					renderHeader: (h, index) => {
						return h("div", [
							h("div", "调整后总价"),
							h("div", "（万元）"),
						]);
					},
				},
				{
					title: "风险预警(万元)",
					width: 150,
					align: "center",
					render: (h, params) => {
						return h(
							"div",
							params.row.result.yjz == "" ?
								"" :
								(params.row.result.yjz / 10000).toFixed(2)
						);
					},
					renderHeader: (h, index) => {
						return h("div", [h("div", "风险预警"), h("div", "(万元)")]);
					},
				},
				{
					title: "抵质押率",
					width: 150,
					align: "center",
					render: (h, params) => {
						return h("div", params.row.result.dzyl * 100 + "%");
					},
				},
				{
					title: "最高可用担保额度",
					width: 150,
					align: "center",
					render: (h, params) => {
						return h("div", (params.row.result.zgkdbed / 10000).toFixed(2));
					},
					renderHeader: (h, index) => {
						return h("div", [
							h("div", "最高可用担保额度"),
							h("div", "（万元）"),
						]);
					},
				},
				{
					title: "租金价格（元/㎡/月）",
					width: 150,
					align: "center",
					render: (h, params) => {
						return h("div", params.row.result.zjdjpg);
					},
					renderHeader: (h, index) => {
						return h("div", [h("div", "租金价格"), h("div", "（元/㎡/月）")]);
					},
				},
				{
					title: "租金（元/月）",
					width: 150,
					align: "center",
					render: (h, params) => {
						return h("div", params.row.result.zjzjpg);
					},
					renderHeader: (h, index) => {
						return h("div", [h("div", "租金"), h("div", "（元/月）")]);
					},
				},
				],
				data: [],
				total: 0,
			},
			showSearchList: false,
			tdLoading: false,
			spf: {
				key: [{
					title: "时间",
					align: "center",
					key: "landTime",
					width: 120,

				},
				{
					title: "城市",
					align: "center",
					key: "shi",
					width: 120,
				},
				{
					title: "行政区",
					align: "center",
					key: "xzq",
					width: 120,
				},
				{
					title: "物业类型",
					align: "center",
					key: "wylx",
					sortable: true,
					width: 120,
				},
				{
					title: "供应套数",
					align: "center",
					key: "gyts",
					width: 120,
				},
				{
					title: "供应面积（万㎡）",
					align: "center",
					width: 120,
					key: "gymj",
					renderHeader: (h, index) => {
						return h("div", [h("div", "供应面积"), h("div", "（万㎡）")]);
					},
				},
				{
					title: "成交套数",
					align: "center",
					key: "cjts",
					width: 120,
				},
				{
					title: "成交面积（万㎡）",
					align: "center",
					width: 120,
					key: "cjmj",
					renderHeader: (h, index) => {
						return h("div", [h("div", "成交面积"), h("div", "（万㎡）")]);
					},
				},
				{
					title: "成交均价（元/㎡）",
					align: "center",
					width: 120,
					key: "cjjj",
					renderHeader: (h, index) => {
						return h("div", [h("div", "成交均价"), h("div", "（元/㎡）")]);
					},
				},
				{
					title: "成交金额（亿元）",
					align: "center",
					width: 120,
					key: "cjje",
					renderHeader: (h, index) => {
						return h("div", [h("div", "成交金额"), h("div", "（亿元）")]);
					},
				},
				{
					title: "库存套数",
					align: "center",
					key: "ccts",
					width: 120,
				},
				{
					title: "库存面积（万㎡）",
					align: "center",
					width: 120,
					key: "ccmj",
					renderHeader: (h, index) => {
						return h("div", [h("div", "库存面积"), h("div", "（万㎡）")]);
					},
				},
				{
					title: "去化周期",
					align: "center",
					key: "qhzq",
					width: 120,
				},
				],
				data: [],
				total: 0,
			},
			ypXq: {},
			ypLs: {
				key: [{
					title: "估价基准日",
					align: "center",
					key: "pgsj",
					render: (h, params) => {
						return h("div", this.formatDate(params.row.pgsj));
					},
				},
				{
					title: "单价（元/㎡）",
					align: "center",
					key: "pgdj",
					renderHeader: (h, index) => {
						return h("div", [h("div", "单价"), h("div", "（元/㎡）")]);
					},
				},
				{
					title: "总价（万元）",
					align: "center",
					key: "pgzj",
					render: (h, params) => {
						return h(
							"div",
							params.row.pgzj == "" ? "" : params.row.pgzj / 10000
						);
					},
					renderHeader: (h, index) => {
						return h("div", [h("div", "总价"), h("div", "（万元）")]);
					},
				},
				{
					title: "租金价格（元/㎡/月）",
					align: "center",
					key: "zjdjpg",
					renderHeader: (h, index) => {
						return h("div", [h("div", "租金价格"), h("div", "（元/㎡/月）")]);
					},
				},
				{
					title: "租金（元/月）",
					align: "center",
					key: "zjzjpg",
					renderHeader: (h, index) => {
						return h("div", [h("div", "租金"), h("div", "（元/月）")]);
					},
				},
				],
				data: [],
			},
			ckalList: [],
			ynpg: {
				key: [
					// {
					//     title: "委托估价方",
					//     align: "center",
					//     key: "wtf",
					//     render: (h, params) => {
					//         return h(
					//             "span",
					//             params.row.wtf[4] +
					//             "****" +
					//             params.row.wtf[params.row.wtf.length - 1]
					//         );
					//     },
					// },
					{
						title: "估价基准日",
						align: "center",
						key: "lastEvalDate",
						render: (h, params) => {
							return h(
								"span", {},
								this.formatDate(params.row.lastEvalDate - 86400000 * 180)
							);
						},
					},
					{
						title: "证载地址",
						key: "zzdz",
						align: "center",
						width: 300,
						tooltip: true,
						ellipsis: true,
						render: (h, params) => {
							return h(
								"Tooltip", {
								props: {
									placement: "top-end",
									transfer: true,
									theme: "light",
								},
							},
								[
									params.row.zzdz, //控制树形显示的内容
									h(
										"div", {
										attrs: {
											class: "btn-detail",
										},
										slot: "content",
										style: {
											whiteSpace: "normal",
										},
										on: {
											click: () => {
												const routeUrl = this.$router.resolve({
													path: "/Empowerment/pledge/pledgeManage/result",
													query: {
														taskId: this.taskEntity.id,
														zzdz: params.row.zzdz,
														resultId: params.row.result.id,
													},
												});

												window.open(routeUrl.href, "_blank");
											},
										},
									},
										params.row.zzdz //控制Tooltip显示的内容
									),
								]
							);
						},
					},
					{
						title: "单价(元/㎡)",
						align: "center",
						key: "pgdj",
						renderHeader: (h, index) => {
							return h("div", [h("div", "单价"), h("div", "(元/㎡)")]);
						},
					},
					{
						title: "总价（万元）",
						align: "center",
						key: "pgzj",
						render: (h, params) => {
							return h(
								"div",
								params.row.pgzj == "" ? "" : params.row.pgzj / 10000
							);
						},
						renderHeader: (h, index) => {
							return h("div", [h("div", "总价"), h("div", "（万元）")]);
						},
					},
				],
				data: [],
			},
			searchAddressList: [],
			searchHaveAddressList: [],
			tabData: {
				厌恶: [],
				交通: [],
				教育: [],
				医疗: [],
				商业: [],
			},
			xqxq: {},
			zbxq: null,
			keyword: "",
			infoModal: false,
		};
	},
	watch: {
		searchListData(newValue) {
			switch (this.tab2) {
				case "tab5":
					this.tabData["商业"] = newValue;
					break;
				case "tab2":
					this.tabData["交通"] = newValue;
					break;
				case "tab4":
					this.tabData["医疗"] = newValue;
					break;
				case "tab3":
					this.tabData["教育"] = newValue;
					break;
				default:
					this.tabData["厌恶"] = newValue;
					break;
			}
		},
		yp(val) {
			if (val) {
				this._yp_jgzs()
				this._yp_gpal();
				this._yp_cjal();
			}
		}
	},

	methods: {
		hideModal() {
			this.infoModal = false;
		},
		showModal() {
			this.infoModal = true;
		},
		_yp_xqxq() {
			yp_xqxq(this.params).then((res) => {
				this.xqxq = res;
			});
		},
		toImage() {
			this.$btnSysLog(1, 2);
			this.$btnlog(1015);
			// 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
			html2canvas(this.$refs.detail, {
				scale: 2,
				useCORS: true,
				allowTaint: true,
				// taintTest: false,
				imageTimeout: 0,
			}).then((canvas) => {
				// 把生成的base64位图片上传到服务器,生成在线图片地址
				this.htmlToPdf(canvas);
			});
		},
		async htmlToPdf(htmlCanvas) {
			// 将canvas对象转为pdf
			const pdf = this.canvasToPdf(htmlCanvas);
			// 通过浏览器下载pdf
			this.downPdf(pdf, "估价报告");
		},
		downPdf(pdfInstance, title) {
			// 文件名过长导致下载失败
			if (title.length > 50) {
				title = title.substring(title.length - 50);
			}

			pdfInstance.save(title + ".pdf", {
				returnPromise: true
			}).then(() => {
				// 搜狗浏览器下载机制问题暂时不关闭
				// if (!(navigator.userAgent.toLowerCase().indexOf("se 2.x") > -1)) {
				// 	setTimeout(window.close, 300);
				// }
			});
		},
		canvasToPdf(htmlCanvas) {
			const canvasWidth = htmlCanvas.width;
			const canvasHeight = htmlCanvas.height;
			const imgBase64 = htmlCanvas.toDataURL("image/jpeg", 1.0);

			// a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
			const imgWidth = 595.28;
			// 图片高度需要等比缩放
			const imgHeight = (595.28 / canvasWidth) * canvasHeight;

			let pageHeight = imgHeight; // pdf转化后页面总高度
			let position = 0;

			const pdfInstance = new jsPdf("", "pt", "a4");
			pdfInstance.setFontSize(12);

			if (imgHeight < 841.89) {
				pdfInstance.addImage(imgBase64, "JPEG", 0, 0, imgWidth, imgHeight);
			} else {
				while (pageHeight > 0) {
					pdfInstance.addImage(
						imgBase64,
						"JPEG",
						0,
						position,
						imgWidth,
						imgHeight
					);
					pageHeight -= 841.89;
					position -= 841.89;
					if (pageHeight > 0) {
						pdfInstance.addPage();
					}
				}
			}

			return pdfInstance;
		},
		changeMapTab(index) {
			this.mapTab = index;
		},
		searchHandle() {
			if (!this.searchAddressList.some((item) => item.zzdz == this.searchValue))
				return this.$msg.error({
					text: "请输入或选择正确的证载地址"
				});
			this.activeTab = 0;
			this.params.zzdz = this.searchValue;
			this.params.taskId = this.searchTaskId;
			this.params.resultId = this.searchResultId;
			this.init();
		},
		_yp_zbxq() {
			let params = JSON.parse(JSON.stringify(this.params))
			params.jd = this.yp.jd
			params.wd = this.yp.wd
			yp_zbxq(params).then((res) => {
				//初始化周边楼盘
				this.ckalList = []
				const myPoint = new BMap.Point(
					this.mapData.center.lng,
					this.mapData.center.lat
				);
				res.forEach((item) => {
					if (item.lpmc == this.ypXq.xqmc) return;
					const point = new BMap.Point(item.jd, item.wd);
					if (+this.map.getDistance(point, myPoint).toFixed(2) < 3000) {
						item.juli = +this.map.getDistance(point, myPoint).toFixed(2);
						this.ckalList.push(item);
					}
				});
				this.ckalList.sort((a, b) => a.juli - b.juli);
				//暂时前端解决数量问题
				this.ckalList = this.ckalList.splice(0, 10);
				if (this.activeTab == 1) {
					this.ckalList.forEach((item) => {
						var point = new BMap.Point(item.jd, item.wd);
						item.marker = new BMap.Marker(point); // 创建标注
						this.map.addOverlay(item.marker);
						var opts = {
							width: 100, // 信息窗口宽度
							height: 50, // 信息窗口高度
							enableMessage: true, //设置允许信息窗发送短息
							message: `${item.dj || "--"}元/m²`,
							offset: new BMap.Size(0, -20),
						};
						var infoWindow = new BMap.InfoWindow(
							`${item.dj || "--"}元/m²`,
							opts
						); // 创建信息窗口对象
						item.marker.addEventListener("click", function () {
							this.deleteMapOverlay('title')
							this.map.openInfoWindow(infoWindow, point); //开启信息窗口
						});
					});
				}
			});
		},
		changeSearch(data) {
			this.params.taskId = data;
			this.yp.taskName = this.searchList.filter(
				(item) => item.id == data
			)[0].taskName;
			this.searchValue = "";
			recordByTaskId({
				taskId: data,
			}).then((res) => {

				this.showSearchList = true;
				this.searchAddressList = [];
				res.map((item) => this.searchAddressList.push(item));
				this.searchHaveAddressList = this.searchAddressList;
			});
		},
		showDetailHandle() {
			this.$refs.content.parentNode.scrollTop = 0;
			this.showDetail = true;
			const value = {
				tab1: [
					"垃圾场",
					"垃圾焚烧厂",
					"垃圾填埋场",
					"殡葬服务",
					"坟墓",
					"陵园",
					"化工厂",
					"核电站",
					"危险品仓库",
					"污水处理厂",
				],
				tab2: [
					"地铁",
					"火车站",
					"高铁站",
					"汽车站",
					// "飞机场",
					"港口",
					"停车场",
					"加油站",
					"公交站",
					"轻轨",
				],
				tab3: [
					"幼儿园",
					"小学",
					"中学",
					"高等院校",
					"成人教育",
					"科研机构",
					"图书馆",
					"科技馆",
					"大学",
					"学院",
				],
				tab4: [
					"医院",
					"药店",
					"疗养院",
					"体检机构",
					"急救中心",
					"疾控中心",
					"医疗保健",
				],
				tab5: [
					"购物中心",
					"百货商场",
					"超级市场",
					"星级酒店",
					"酒店",
					"美食",
					"休闲娱乐",
					"银行",
					"旅游景点",
					"写字楼",
				],
			};

			this.chart9 && this.chart9.dispose();
			Object.keys(value).forEach((item, index) => {
				setTimeout(() => {
					this.searchNearBy(value[item], this.searchWidth * 1000);
				}, 200 * index);
			});
			this.$nextTick(() => {
				this.loadMap2(BMap);
				this.initCharItem();
			});
		},
		hideDetailHandle() {
			this.$refs.content.parentNode.scrollTop = 0;
			this.showDetail = false;
			this.disChart();
			this.$nextTick(() => {
				this.loadMap(BMap);
			});
		},
		toRegionResult() {
			this.$router.push({
				path: "/Empowerment/pledge/pledgeManage/regionResult",
				query: {
					xqmc: this.ypXq.xqmc,
					xqId: this.ypXq.xqId,
					csId: this.ypXq.csId,
					cs: this.ypXq.cs,
				},
			});
		},
		initCharItem() {
			this.myChart = this.$echarts.init(document.getElementById("chart2"));
			this.chart5 = this.$echarts.init(document.getElementById("chart5"));
			this.chart6 = this.$echarts.init(document.getElementById("chart6"));
			this.chart7 = this.$echarts.init(document.getElementById("chart7"));
			this.chart8 = this.$echarts.init(document.getElementById("chart8"));
			this.myChart.setOption(this.option3);
			this.chart5.setOption(this.option);
			this.chart6.setOption(this.option2);
			this.chart7.setOption(this.option3);
			this.chart8.setOption(this.option3);
		},
		disChart() {
			this.myChart && this.myChart.dispose();
			this.chart5 && this.chart5.dispose();
			this.chart6 && this.chart6.dispose();
			this.chart7 && this.chart7.dispose();
			this.chart8 && this.chart8.dispose();
		},

		loadMap(BMap) {
			let that = this;
			this.map = new BMap.Map("map");
			// 创建地址解析器实例
			var myGeo = new BMap.Geocoder();
			// 将地址解析结果显示在地图上,并调整地图视野
			myGeo.getPoint(
				this.searchValue,
				function (point) {
					if (point) {
						// that.mapData.center = point;
						const myPoint = new BMap.Point(
							that.mapData.center.lng,
							that.mapData.center.lat
						);
						const icon = new BMap.Icon(that.liveIcon, new BMap.Size(50, 50));
						icon.setSize(new BMap.Size(50, 50));
						icon.setImageSize(new BMap.Size(50, 50));
						that.map.centerAndZoom(myPoint, 13);
						that.map.enableScrollWheelZoom();
						let marker = new BMap.Marker(myPoint, {
							icon
						});

						marker.addEventListener("click", () => {
							that.map.centerAndZoom(myPoint, 14);
							//点击时添加小区名字
							that.addInfo(that, myPoint)

						});
						that.map.addOverlay(marker);
						that.initNearBySearch(myPoint); // 初始化周边搜索
					} else {
						alert("您选择地址没有解析到结果!");
					}
				},
				"中国"
			);
		},
		//点击时添加小区名字
		addInfo(vm, point) {
			//点击时添加小区名字
			var opts = {
				position: point, // 指定文本标注所在的地理位置
				offset: new BMap.Size(0, -80), // 设置文本偏移量
			};
			let info = new BMap.Label(vm.yp.xqmc, opts);
			// 自定义文本标注样式
			info.setStyle({
				color: "red",
				borderRadius: "5px",
				borderColor: "red",
				fontSize: "16px",
				height: "40px",
				lineHeight: "36px",
				fontFamily: "微软雅黑",
				paddingLeft: '10px',
				paddingRight: '10px',
			});
			//给标注添加name属性，以便删除
			info.name = 'title'
			vm.map.addOverlay(info);
		},
		attribute(e) {
			var p = e.target;
			alert("marker的位置是" + p.getPosition().lng + "," + p.getPosition().lat);
		},
		initNearBySearch(myPoint) {
			let that = this;
			this.nearbySearch = new BMap.LocalSearch(this.map, {
				renderOptions: {
					map: this.map,
					autoViewport: false,
					selectFirstResult: false,
				},
				pageCapacity: 100,

				onSearchComplete(result) {
					that.keyword = result[0].keyword;
				},
				onMarkersSet(result) {
					const myPoint = new BMap.Point(
						that.mapData.center.lng,
						that.mapData.center.lat
					);
					// 去重
					let newRes = [];
					for (let i = 0; i < result.length; i++) {
						if (
							newRes.findIndex(
								(nr) =>
									nr.point.lng === result[i].point.lng &&
									nr.point.lat === result[i].point.lat
							) < 0
						) {
							newRes.push(result[i]);
						}
					}
					newRes.forEach((item) => {
						const point = new BMap.Point(item.point.lng, item.point.lat);
						item.juli = +that.map.getDistance(point, myPoint).toFixed(2);
					});
					newRes = newRes.filter((item) => item.juli <= 5000);
					newRes.sort((a, b) => {
						return a.juli - b.juli;
					});
					//   newRes = newRes.map((item) => {
					//     if (Array.isArray(item.tags) && item.tags.length > 0) {
					//       item.tag = item.tags[item.tags.length - 1];
					//     }
					//     return item;
					//   });
					if (that.showDetail) {
						switch (that.keyword) {
							case "购物":
								that.tabData["商业"] = newRes;
								break;
							case "地铁":
								that.tabData["交通"] = newRes;
								break;
							case "医院":
								that.tabData["医疗"] = newRes;
								break;
							case "幼儿园":
								that.tabData["教育"] = newRes;
								break;
							default:
								that.tabData["厌恶"] = newRes;
								break;
						}
					} else {
						that.searchListData = newRes;
					}
				},
			});
		},
		clickPoint(item) {
			item.marker.ba.click();
		},
		chooseSearchAddress(item) {
			this.searchValue = item.zzdz;
			this.searchTaskId = item.taskId;
			this.searchResultId = item.resultId;
			this.searchHaveAddressList = [];
		},
		inputSearch() {
			this.searchHaveAddressList = [];
			this.searchAddressList.map((item) => {
				if (item.zzdz.indexOf(this.searchValue) > -1) {
					this.searchHaveAddressList.push(item);
				}
			});
		},
		searchNearBy(searchType, range) {
			this.map && this.map.clearOverlays();
			const myPoint = new BMap.Point(
				this.mapData.center.lng,
				this.mapData.center.lat
			);
			const icon = new BMap.Icon(this.liveIcon, new BMap.Size(50, 50));
			icon.setSize(new BMap.Size(50, 50));
			icon.setImageSize(new BMap.Size(50, 50));
			const marker = new BMap.Marker(myPoint, {
				icon
			});
			this.map.addOverlay(marker);
			marker.addEventListener("click", () => {
				this.map.centerAndZoom(myPoint, 14);
				//点击时添加小区名字
				this.addInfo(this, myPoint)
			});
			this.addCircle(myPoint, range);
			// this.nearbySearch.setPageCapacity(5)
			this.nearbySearch.searchNearby(searchType, myPoint, range * 1);
		},
		loadMap2(BMap) {
			this.map = new BMap.Map("map2");
			const icon = new BMap.Icon(this.liveIcon, new BMap.Size(50, 50));
			const myPoint = new BMap.Point(
				this.mapData.center.lng,
				this.mapData.center.lat
			);
			icon.setSize(new BMap.Size(50, 50));
			icon.setImageSize(new BMap.Size(50, 50));
			const marker = new BMap.Marker(myPoint, {
				icon
			});
			marker.addEventListener("click", () => {
				this.map.centerAndZoom(myPoint, 14);
				//点击时添加小区名字
				this.addInfo(this, myPoint)
			});
			this.map.addOverlay(marker);
			this.map.centerAndZoom(myPoint, this.mapData.zoom);
			this.map.enableScrollWheelZoom();
		},
		handleSpan({
			row,
			column,
			rowIndex,
			columnIndex
		}) {
			if (rowIndex % 10 == 0 && columnIndex === 0) {
				return {
					rowspan: 10,
					colspan: 1,
				};
			} else if (rowIndex % 10 == 0 && columnIndex === 1) {
				return {
					rowspan: 10,
					colspan: 1,
				};
			} else if (rowIndex % 5 == 0 && columnIndex === 2) {
				return {
					rowspan: 5,
					colspan: 1,
				};
			} else if (rowIndex % 10 !== 0 && columnIndex === 0) {
				return {
					rowspan: 0,
					colspan: 0,
				};
			} else if (rowIndex % 10 !== 0 && columnIndex === 1) {
				return {
					rowspan: 0,
					colspan: 0,
				};
			} else if (rowIndex % 5 !== 0 && columnIndex === 2) {
				return {
					rowspan: 0,
					colspan: 0,
				};
			}
		},
		clickCkal(item) {
			this.deleteMapOverlay('title')
			this.addInfoWindow(this, item)
		},
		chooseTab(item) {
			this.activeTab = item.id;
			if (!this.map) return;
			this.ckalList.forEach((item) => {
				if (item.marker) {
					this.map.removeOverlay(item.marker);
				}
			});
			if (item.id == 2) {
				this.chart9 && this.chart9.dispose();
				this.searchItem = [
					"地铁",
					"火车站",
					"高铁站",
					"汽车站",
					// "飞机场",
					"港口",
					"停车场",
					"加油站",
					"公交站",
				];
				this.searchNearBy(this.searchItem, this.searchWidth * 1000);
			} else if (item.id == 4) {
				this.$nextTick(() => {
					this.chart9 = this.$echarts.init(document.getElementById("chart9"));
					this.chart9.setOption(this.option3);
				});
			} else {
				this.chart9 && this.chart9.dispose();
				this.map.clearOverlays();
				const icon = new BMap.Icon(this.liveIcon, new BMap.Size(50, 50));
				const myPoint = new BMap.Point(
					this.mapData.center.lng,
					this.mapData.center.lat
				);
				icon.setSize(new BMap.Size(50, 50));
				icon.setImageSize(new BMap.Size(50, 50));
				const marker = new BMap.Marker(myPoint, {
					icon
				});
				marker.addEventListener("click", () => {
					this.map.centerAndZoom(myPoint, 14);
					//点击时添加小区名字
					this.addInfo(this, myPoint)

				});
				this.map.addOverlay(marker);
			}
			if (item.id == 1) {
				let that = this
				this.ckalList.forEach((item) => {
					var point = new BMap.Point(item.jd, item.wd);
					item.marker = new BMap.Marker(point); // 创建标注
					this.map.addOverlay(item.marker);
					item.marker.addEventListener("click", function () {
						that.deleteMapOverlay('title')
						that.addInfoWindow(this, item)

					});
				});
				const myPoint = new BMap.Point(
					this.mapData.center.lng,
					this.mapData.center.lat
				);
				this.addCircle(myPoint, 3000);
			}
		},
		//删除标注
		deleteMapOverlay(name) {
			let allOverlays = this.map.getOverlays()
			allOverlays.forEach(overLay => {
				if (overLay.name == name) {
					this.map.removeOverlay(overLay)
				}
			})
		},
		//添加点击显示信息事件
		addInfoWindow(vm, item) {
			var point = new BMap.Point(item.jd, item.wd);
			var opts = {
				width: 100, // 信息窗口宽度
				height: 50, // 信息窗口高度
				title: item.lpmc, // 信息窗口标题
				enableMessage: true, //设置允许信息窗发送短息
				message: `${item.dj || "--"}元/m²`,
				offset: new BMap.Size(0, -20),
			};
			var infoWindow = new BMap.InfoWindow(`${item.dj || "--"}元/m²`, opts); // 创建信息窗口对象
			vm.map.openInfoWindow(infoWindow, point)
		},
		addCircle(point, long) {
			const circle = new BMap.Circle(point, long, {
				strokeColor: "#00b6ff",
				strokeWeight: 3,
				strokeOpacity: 1,
				strokeStyle: "dashed",
				fillColor: "",
			});
			this.map.addOverlay(circle);
		},
		chooseTab2(name) {
			const value = {
				tab1: [
					"垃圾场",
					"垃圾焚烧厂",
					"垃圾填埋场",
					"殡葬服务",
					"坟墓",
					"陵园",
					"化工厂",
					"核电站",
					"危险品仓库",
					"污水处理厂",
				],
				tab2: [
					"地铁",
					"火车站",
					"高铁站",
					"汽车站",
					"飞机场",
					"港口",
					"停车场",
					"加油站",
					"公交站",
					"轻轨",
				],
				tab3: [
					"幼儿园",
					"小学",
					"中学",
					"高等院校",
					"成人教育",
					"科研机构",
					"图书馆",
					"科技馆",
					"大学",
					"学院",
				],
				tab4: [
					"医院",
					"药店",
					"疗养院",
					"体检机构",
					"急救中心",
					"疾控中心",
					"医疗保健",
				],
				tab5: [
					"购物中心",
					"百货商场",
					"超级市场",
					"星级酒店",
					"酒店",
					"美食",
					"休闲娱乐",
					"银行",
					"旅游景点",
					"写字楼",
				],
			};
			this.searchItem = value[name];
			this.searchNearBy(this.searchItem, this.searchWidth * 1000);
		},
		formatDate: function (val) {
			const value = new Date(val);
			const year = value.getFullYear();
			const month = value.getMonth() + 1;
			const day = value.getDate();
			// const hour=(value.getHours());
			// const minutes=(value.getMinutes());
			// const seconds=(value.getSeconds());
			return (
				year +
				"-" +
				(month >= 10 ? month : "0" + month) +
				"-" +
				(day >= 10 ? day : "0" + day)
			);
		},
		_rwlb(init = false) {
			rwlb({
				current: 1,
				size: 9999,
				state: 1,
			}).then((res) => {
				this.searchList = res.records;
				if (init) return;
				if (this.searchList.length > 0) {
					if (this.params.taskId == "") {
						this.yp.taskName = this.searchList[0].taskName;
						this.params.taskId = this.searchList[0].id;
					} else {
						this.yp.taskName = this.searchList.filter(
							(item) => item.id == this.params.taskId
						)[0].taskName;
					}
				}
				recordByTaskId({
					taskId: this.params.taskId,
				}).then((res) => {
					this.showSearchList = true;
					this.searchAddressList = [];
					res.map((item) => this.searchAddressList.push(item));
					this.searchHaveAddressList = this.searchAddressList;
					this.chooseSearchAddress(this.searchHaveAddressList[0]);
					this.searchHandle();
				});
			});
		},
		_one_yp() {
			one_yp(this.params).then((res) => {
				this.yp = res;
				this.mapData.center = {
					lng: Number(this.yp.jd),
					lat: Number(this.yp.wd),
				};
				this.searchValue = res.zzdz;
				this.loadMap(BMap);
				this._yp_zbxq();
			});
		},
		_yp_cjal() {
			//成交案例获取
			let params = this.params
			params.pgjzr = this.yp.pgsj / 1000
			params.pgsj = this.yp.pgsj
			yp_cjal(params).then((res) => {
				let _baseTime = this.yp.pgsj;
				let halfYear = _baseTime - 365 * 24 * 60 * 60 * 1000;
				//基准日
				// let _list = res.records.filter((item) => {
				//     // return Date.parse(item.cjsj) < _baseTime;
				//     return Date.parse(item.cjsj) < _baseTime ?
				//         Date.parse(item.cjsj) > halfYear :
				//         "";
				// });
				//对成交案例进行时间限制，在基准日之后的数据都会被过滤掉
				// this.ckalCjList = _list.splice(0, 5);
				this.ckalCjList = res.records
			});
		},
		_yp_gpal() {
			//挂牌案例获取
			let params = this.params
			params.pgsj = this.yp.pgsj
			params.pgjzr = this.yp.pgsj / 1000
			yp_gpal(params).then((res) => {
				// let _baseTime = this.yp.pgsj;
				// let halfYear = _baseTime - 365 * 24 * 60 * 60 * 1000;
				// //基准日
				// let _list = res.records.filter((item) => {
				//     return Date.parse(item.gpsj) < _baseTime ?
				//         Date.parse(item.gpsj) > halfYear :
				//         "";
				// });
				//对挂牌案例进行时间限制，在基准日之后的数据都会被过滤掉
				// this.ckalGpList = _list.splice(0, 5);
				this.ckalGpList = res.records
			});
		},

		_xqyp_list() {
			xqyp_list(this.params).then((res) => {
				this.xqyp.data = res.records;
				this.xqyp.total = res.total;
			});
		},
		changeXqyp(page) {
			this.params.current = page;
			xqyp_list(this.params).then((res) => {
				this.xqyp.data = res.records;
				this.xqyp.total = res.total;
			});
		},
		pageSizeChangeXqyp(pageSize) {
			this.params.current = 1;
			this.params.size = pageSize;
			xqyp_list(this.params).then((res) => {
				this.xqyp.data = res.records;
				this.xqyp.total = res.total;
			});
		},
		_yp_td() {
			this.tdLoading = true;
			yp_tdtj({
				current: this.params.current,
				landUses: this.params.landUses || [],
				size: this.params.size,
				provinceIds: this.ypXq.csId,
				timePeriod: this.params.timePeriod || 1,
			})
				.then((res) => {
					this.td.data = res.records;
					this.td.total = res.total;
				})
				.finally(() => {
					this.tdLoading = false;
				});
		},
		changeTd(page) {
			this.params.current = page;
			this._yp_td();
		},
		changeTdOption(value, name) {
			switch (name) {
				case "土地用途":
					this.params.landUses = value;
					break;
				case "周期":
					this.params.timePeriod = value;
					break;
			}
			this.params.current = 1;
			this._yp_td();
		},
		pageSizeChangeTd(pageSize) {
			this.params.current = 1;
			this.params.size = pageSize;
			this._yp_td();
		},
		_yp_sfpm() {
			yp_sfpm(this.params).then((res) => {
				this.sfpm.data = res.records;
				this.sfpm.total = res.total;
			});
		},
		changeSfpm(page) {
			this.params.current = page;
			yp_sfpm(this.params).then((res) => {
				this.sfpm.data = res.records;
				this.sfpm.total = res.total;
			});
		},
		pageSizeChangeSfpm(pageSize) {
			this.params.current = 1;
			this.params.size = pageSize;
			yp_sfpm(this.params).then((res) => {
				this.sfpm.data = res.records;
				this.sfpm.total = res.total;
			});
		},
		_yp_cqjys() {
			yp_cqjys(this.params).then((res) => {
				this.cqjys.data = res.records;
				this.cqjys.total = res.total;
			});
		},
		changeCqjys(page) {
			this.params.current = page;
			yp_cqjys(this.params).then((res) => {
				this.cqjys.data = res.records;
				this.cqjys.total = res.total;
			});
		},
		pageSizeChangeCqjys(pageSize) {
			this.params.current = 1;
			this.params.size = pageSize;
			yp_cqjys(this.params).then((res) => {
				this.cqjys.data = res.records;
				this.cqjys.total = res.total;
			});
		},
		_yp_wylx() {
			yp_wylx().then((res) => {
				//初始化物业类型列表
				this.tableOption3[0].optionList = []
				res.forEach((i) => {
					this.tableOption3[0].optionList.push({
						label: i,
						value: i,
					});
				});
			});
		},
		_yp_zxq() {
			yp_zxq({
				zzdz: this.params.zzdz,
				taskId: this.params.taskId,
			}).then((res) => {
				//初始化行政区列表
				this.tableOption3[1].optionList = []
				res.forEach((i) => {
					this.tableOption3[1].optionList.push({
						label: i.name,
						value: i.id,
					});
				});
			});
		},
		_yp_spf() {
			if (!(this.params.taskId && this.params.zzdz)) return;
			this.spfLoding = true;
			yp_spf({
				current: this.params.current,
				taskId: this.params.taskId,
				zzdz: this.params.zzdz,
				zq: this.params.zq || [],
				size: this.params.size,
				wylx: this.params.wylx || [],
				xzq: this.params.xzq || [],
			})
				.then((res) => {
					//
					this.spf.data = res.records;
					this.spf.total = res.total;
				})
				.finally(() => {
					this.spfLoding = false;
				});
		},
		changeSpf(page) {
			this.params.current = page;
			this._yp_spf();
		},
		getLandUse() {
			getLandUse2().then((res) => {
				this.tableOption2[0].optionList = res.reduce(
					(optionArr, item, index) => {
						optionArr.push({
							value: item.id,
							label: item.name
						});
						this.$set(this.tableOption2[0].value, index, item.id);
						return optionArr;
					},
					[]
				);
				this._yp_td();
			});
		},
		changeSpfOption(value, name) {
			if (!value) return;
			switch (name) {
				case "物业类型":
					this.params.wylx = value;
					break;
				case "行政区":
					this.params.xzq = value;
					break;
				case "周期":
					this.params.zq = value == '1' ? ["月"] : ["年"];
					break;
			}
			this.params.current = 1;
			this._yp_spf();
		},
		pageSizeChangeSpf(pageSize) {
			this.params.current = 1;
			this.params.size = pageSize;
			this._yp_spf();
		},
		_yp_xq() {
			yp_xq(this.params).then((res) => {
				this.ypXq = res;
				this._yp_td();
			});
		},
		_yp_history() {
			yp_history(this.params).then((res) => {
				this.ypLs.data = res.records;
			});
		},
		_yp_ynpg() {
			yp_ynpg(this.params).then((res) => {
				this.ynpg.data = res.records;
			});
		},
		_yp_jgzs() {
			let params = this.params
			params.pgsj = this.yp.pgsj
			yp_jgzs(params).then((res) => {
				res.shift()

				if (res.length > 0) {
					let data0 = new Array();
					let data1 = new Array();
					let data2 = new Array();
					let xAxis = new Array();
					res.forEach((item) => {
						data0.unshift(+item.lpPrice);
						data1.unshift(+item.csPrice);
						data2.unshift(+item.districtPrice);
						xAxis.unshift(item.dataDate);
					});
					const option = {
						tooltip: {
							trigger: "axis",
						},
						legend: {
							top: "14px",
							left: "0px",
							itemWidth: 12,
							itemHeight: 12,
							itemGap: 5,
							textStyle: {
								color: "#666",
								fontSize: 12,
							},
							data: [res[0].lpmc, res[0].district, res[0].cs],
						},
						color: ["#5AD8A6", "#5B8FF9", "#5D7092"],
						grid: {
							left: "0%",
							right: "4%",
							bottom: "3%",
							containLabel: true,
						},
						xAxis: {
							type: "category",
							boundaryGap: false,
							data: xAxis,
							axisLabel: {
								textStyle: {
									color: "#666",
								},
							},
							axisLine: {
								show: true,
								lineStyle: {
									color: "#D6D7D9",
								},
							},
						},
						yAxis: {
							type: "value",
							axisLabel: {
								textStyle: {
									color: "#666",
								},
							},
							axisLine: {
								show: true,
								lineStyle: {
									color: "#D6D7D9",
								},
							},
						},
						series: [{
							name: res[0].lpmc,
							type: "line",
							data: data0,
							color: "#ff1e10",
							lineStyle: {
								color: "#ff1e10", //改变折线颜色
							},
						},
						{
							name: res[0].district,
							type: "line",
							data: data2,
							color: "#1aad19",
							lineStyle: {
								color: "#1aad19", //改变折线颜色
							},
						},
						{
							name: res[0].cs,
							type: "line",
							data: data1,
							color: "#1a9bff",
							lineStyle: {
								color: "#1a9bff", //改变折线颜色
							},
						},
						],
					};
					this.option3 = option;
				}
			});
		},
		init() {
			this._one_yp();
			// this._yp_gpal();
			// this._yp_cjal();
			this._xqyp_list();
			this._yp_sfpm();
			this._yp_cqjys();
			this._yp_spf();
			this._yp_xq();
			this._yp_history();
			this._yp_ynpg();
			this._yp_xqxq();

			this._rwlb(true);
			this._yp_wylx();
			this._yp_zxq();
			this.getLandUse();
			// setTimeout(() => {
			//   this._yp_jgzs();
			// }, 1000);
		},
		clear() {
			this.showSearchList = false;
		},
		searchByWidth() {
			if (!this.searchWidth) {
				this.$msg.error({
					type: "remind",
					code: 4047
				});

				return;
			}
			if (!/^-?\d+(\.\d+)?$/.test(this.searchWidth)) {
				// this.$Message.warning("请输入正确的数字");
				this.$msg.error({
					type: "remind",
					code: 4046
				});

				return;
			}
			if (this.searchWidth > 5) {
				this.searchWidth = "5";
				// this.$Message.warning("搜索最大范围是5000米，已为你设置成最大值");

				this.$msg.error({
					type: "remind",
					code: 4048
				});
			}
			this.searchNearBy(this.searchItem, this.searchWidth * 1000);
		},
		showTips() {
			this.$msg.error({
				type: "tips",
				code: 3065
			});
		},
	},
	filters: {
		formatDate: function (val) {
			const value = new Date(val);
			const year = value.getFullYear();
			const month = value.getMonth() + 1;
			const day = value.getDate();
			const hour = value.getHours();
			const minutes = value.getMinutes();
			const seconds = value.getSeconds();
			return (
				year +
				"-" +
				(month >= 10 ? month : "0" + month) +
				"-" +
				(day >= 10 ? day : "0" + day)
			);

			//return year + '-' + (month >= 10 ? month : '0' + month) + '-' + (day >= 10 ? day : '0' + day) + " " + (hour >= 10 ? hour : '0' + hour) + ":" + (minutes >= 10 ? minutes : '0' + minutes) + ":" + (seconds >= 10 ? seconds : '0' + seconds);
		},
	},
	mounted() {
		document.addEventListener("click", this.clear);


		if (this.$route.query.taskId) {
			this.params.taskId = this.$route.query.taskId;
			this.params.zzdz = this.$route.query.zzdz;
			this.params.resultId = this.$route.query.resultId;
			this.searchValue = this.$route.query.zzdz;
		}
		if (this.params.taskId != "" && this.params.zzdz != "") {
			this.init();
		} else {
			this._rwlb();
		}
	},
	destroyed() {
		document.removeEventListener("click", this.clear);
	},
};
</script>

<style lang="scss">
@import "@/style/common.scss";

.result-box {
	width: 100%;
	// margin: 20px;
	padding: 20px 0;
	box-sizing: border-box;
	height: 100%;
	overflow: auto;

	.ivu-dropdown-menu {
		width: 331px !important;
		max-height: 500px;
	}

	.optionBox {
		max-height: 500px;
	}

	.detail {
		color: #333333;
		position: relative;

		.detailBox {
			padding: 30px 20px 10px;
		}

		.title {
			font-size: 24px;
			font-weight: bold;
		}

		.sData {
			font-size: 14px;
			margin-top: 10px;

			span {
				margin-right: 60px;
			}
		}

		.downloadBtn {
			width: 76px;
			height: 40px;
			@include flex;
			position: absolute;
			background: #00b6ff;
			border-radius: 5px;
			top: 10px;
			right: 20px;
			color: #fff;
			border-color: #00b6ff;
		}

		.backBtn {
			position: absolute;
			top: -10px;
			left: 20px;
		}

		.grayLine {
			width: 100%;
			height: 1px;
			background: #d6d7d9;
			margin: 20px auto;
		}

		.detailItem {
			.itemTitle {
				font-size: 18px;
				font-weight: bold;
				color: #1562d6;
				margin-bottom: 20px;
			}

			.itemInfo {
				font-size: 14px;
				margin-bottom: 20px;
			}

			.sTitle {
				font-size: 14px;
				font-weight: bold;
				margin-bottom: 10px;
			}

			.resultBox {
				display: grid;
				grid-template-columns: repeat(auto-fill, 170px);
				grid-gap: 6px;
				margin-bottom: 20px;

				.resultItem {
					background: #f3f7ff;
					height: 100px;
					text-align: center;

					.info:nth-of-type(1) {
						font-size: 14px;
						color: #1562d6;
						margin: 10px auto;
					}

					.info:nth-of-type(2) {
						font-size: 16px;
						color: #1562d6;
						margin-bottom: 12px;
					}

					.info:nth-of-type(3) {
						font-size: 12px;
						color: #666;
					}

					&.danger {
						background: #fff3f3;
						color: #ff3333;

						.info:nth-of-type(1) {
							font-size: 14px;
							color: #ff3333;
							margin: 10px auto;
						}

						.info:nth-of-type(2) {
							font-size: 16px;
							color: #ff3333;
							margin-bottom: 12px;
						}

						.info:nth-of-type(3) {
							font-size: 12px;
							color: #ff3333;
						}
					}
				}
			}

			.infoBox {
				display: flex;
				margin: 20px 0;

				.left,
				.right {
					flex: 1;
				}

				.left {
					.detailInfo {
						height: 348px;
						border: 1px solid #d6d7d9;
						background: #fcfcff;
						margin-top: 10px;
						overflow: auto;
						line-height: 40px;

						.info {
							display: grid;
							grid-template-columns: repeat(2, 1fr);

							.line {
								display: flex;
								height: 40px;

								.name {
									flex: 1;
									color: #333;
									font-weight: bold;
									text-align: right;
									white-space: nowrap;
								}

								.data {
									flex: 2;
									color: #333333;
									margin-left: 10px;
									white-space: nowrap;
									overflow: hidden;
									text-overflow: ellipsis;
								}
							}
						}
					}
				}

				.right {
					margin-left: 10px;

					.map {
						height: 348px;
						width: 100%;
						background: #eee;
						margin-top: 10px;
					}
				}
			}

			.hList {
				display: grid;
				grid-template-columns: repeat(3, minmax(460px, 1fr));
				margin-bottom: 20px;

				.item {
					height: 110px;
					width: 100%;
					position: relative;

					&::after {
						content: "";
						background: #d6d7d9;
						width: 460px;
						height: 1px;
						position: absolute;
						bottom: 0;
						left: 0;
					}

					&:last-child {
						border-bottom: none;
					}

					img {
						display: block;
						position: absolute;
						top: 14px;
						left: 0;
						width: 105px;
						height: 80px;
						background: #00b6ff;
					}

					.title {
						font-size: 16px;
						color: #333;
						position: absolute;
						top: 14px;
						left: 124px;
					}

					.size {
						position: absolute;
						top: 45px;
						left: 124px;
						color: #666;
						font-size: 14px;

						span {
							margin-right: 20px;
						}
					}

					.address {
						position: absolute;
						top: 45px;
						left: 124px;
						color: #666;
						font-size: 14px;

						.mapIcon {
							height: 16px;
							width: 16px;
							transform: translateY(3px);
						}
					}

					.time {
						color: #333;
						position: absolute;
						top: 74px;
						left: 124px;
					}

					.priceItem {
						position: absolute;
						left: 340px;
						display: flex;
						align-items: center;
						white-space: nowrap;

						.icon {
							width: 18px;
							height: 18px;
							font-size: 12px;
							@include flex-center;
							margin-left: 7px;
							border: 1px solid #e0e0e0;
							border-radius: 2px;
						}

						.price {
							color: #ff3333;
							font-size: 16px;
							margin-right: 6px;
						}

						.dw {
							font-size: 12px;
							color: #333;
							white-space: nowrap;
						}

						&.sPrice {
							top: 14px;
						}

						&.aPrice {
							top: 43px;

							.icon {
								border-color: #ff3333;
								color: #ff3333;
							}
						}
					}
				}
			}

			.contentInfo {
				height: 633px;
				margin: 20px auto;
				display: flex;
				box-sizing: border-box;

				.left {
					width: 527px;
					height: 100%;
					border: 1px solid #d6d7d9;
					background: #fcfcff;
					margin-right: 10px;
					padding: 20px;
					overflow: auto;

					.contentLine {
						margin-bottom: 20px;
						display: flex;
						font-size: 16px;
						text-align: left;

						.contentTitle {
							flex: 1;
							font-weight: bold;
							color: #333;
						}

						.content {
							flex: 4;
							color: #666;

							&.norwap {
								flex: 4;
							}
						}
					}
				}

				.right {
					flex: 1;
					height: 100%;
					border: 1px solid #d6d7d9;
					background: #fcfcff;
					padding: 20px;
					box-sizing: border-box;

					#chart2 {
						height: calc(100% - 59px);
						width: 100%;
					}
				}
			}

			.hLine {
				display: flex;

				.htitle {
					font-size: 14px;
					font-weight: bold;
					color: #00b6ff;
					display: inline;
					line-height: 30px;
					width: 50px;
					min-width: 50px;
				}

				.hitemList {

					// display: grid;
					// grid-row-gap: 10px;
					// grid-column-gap: 10px;
					// grid-template-rows: repeat(auto-fill, 1fr);
					.item {
						height: 30px;
						line-height: 16px;
						padding: 7px;
						background: #f5f5f5;
						display: inline-block;
						margin-bottom: 10px;
						margin-right: 10px;

						.name {
							margin-right: 30px;
						}

						.type {
							height: 20px;
							line-height: 20px;
							border-radius: 2px;
							border: 1px solid #ee7d78;
							color: #ee7d78;
							font-size: 12px;
							margin-left: 10px;
						}
					}
				}
			}

			.chartItem {
				height: 280px;
				width: 100%;
				margin: 10px auto 20px;
			}
		}
	}

	.content-box {
		margin: 0 20px;

		.search-bar {
			position: relative;
			width: 100%;
			height: 76px;
			margin-bottom: 20px;
			display: flex;
			align-items: center;
			white-space: nowrap;

			.left-title {
				cursor: pointer;
			}

			.center {
				width: 993px;
				height: 76px;
				border: 1px solid #d6d7d9;
				border-radius: 38px;
				margin-left: 231px;
				display: flex;
				position: relative;

				.searchOption {
					width: 331px;
					height: 76px;
					color: #333333;
					font-size: 20px;
					@include flex-center;
					cursor: pointer;
					position: relative;

					.downIcon {
						@include flex-center;
						right: 34px;
						top: 50%;
						transform: translateY(-50%);
						color: #aaaaaa;
						position: absolute;
					}
				}

				.searchInput {
					height: 100%;
					width: 541px;
					border: none;
					border-left: 1px solid #e8e8e8;
					outline: none;
					box-sizing: border-box;
					padding: 0 10px;
					font-size: 16px;
				}

				.searchBtn {
					width: 120px;
					height: 100%;
					background: #1562d6;
					box-shadow: 0px 0px 12px 0px rgba(41, 53, 92, 0.2);
					border-radius: 0px 38px 38px 0px;
					font-size: 20px;
				}

				.searchTipsBox {
					width: 650px;
					position: absolute;
					top: 80px;
					left: 224px;
					background: #fff;
					z-index: 100;
					max-height: 600px;
					overflow: auto;
					box-shadow: 0 0 10px 1px #e1e1e1;

					.searchTipsItem {
						width: 100%;
						height: 60px;
						display: flex;
						align-items: center;
						padding: 16px 5px 16px;
						box-sizing: border-box;
						cursor: pointer;

						&:hover {
							background: #f5f5f5;
						}

						.searchTipsTitle {
							height: 28px;
							background: rgba(67, 115, 232, 1);
							border-radius: 5px;
							box-sizing: border-box;
							padding: 8px 12px;
							font-size: 14px;
							@include flex-center;
							color: #fff;
						}

						.key {
							font-size: 14px;
							color: #666666;
							margin-left: 9px;
						}

						.item {
							color: #333333;
							font-size: 14px;
							margin-left: 10px;
						}
					}
				}
			}
		}

		.top-box {
			width: 100%;
			display: flex;

			.left-top-box {
				width: 480px;

				.place-info {
					width: 100%;
					height: 500px;
					border: 1px solid #d6d7d9;
					box-sizing: border-box;
					padding: 20px;
					position: relative;

					.title {
						font-size: 20px;
						font-weight: bold;
						color: #333333;
					}

					.time {
						font-size: 14px;
						color: #999;
						margin: 10px 0;
					}

					.address {
						width: 440px;
						height: 40px;
						background: #f2f2f2;
						color: #333333;
						font-size: 16px;
						vertical-align: top;

						// ::v-deep .ivu-tooltip {
						//     width: 370px !important;
						//     .ivu-tooltip-rel {
						//         max-width: 370px;
						//         overflow: hidden;
						//     }
						// }
						p {
							display: inline-block;
							width: 380px;
							height: 40px;
							background: #f2f2f2;
							color: #333333;
							line-height: 40px;
							font-size: 16px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							position: relative;
							left: 20px;
						}

						.test {
							color: red;
						}

						.mapIcon {
							width: 20px;
							display: inline-block;
							position: relative;
							top: -10px;
							left: 10px;
						}
					}

					.resultInfo {
						position: absolute;
						right: 12px;
						top: 12px;
						cursor: pointer;
					}

					.price {
						height: 104px;
						width: 100%;
						display: flex;

						.i-price {
							height: 100%;
							flex: 1;
							text-align: center;

							.p-title {
								color: #666666;
								font-size: 14px;
								margin: 20px auto 10px;
							}

							.p-num {
								color: #ff3333;
								font-weight: bold;
								font-size: 20px;
								margin-bottom: 3px;
							}

							.r-num {
								color: #666666;
								font-size: 12px;
							}
						}
					}

					.referTips {
						border-bottom: 1px solid #d6d7d9;
						padding: 10px 30px;

						opacity: 1;
						font-size: 13px;
						font-family: Source Han Sans CN, Source Han Sans CN-Regular;
						font-weight: 400;
						text-align: left;
						color: #333333;
						letter-spacing: 1px;

					}

					.other-info {
						border-bottom: 1px solid #d6d7d9;

						.top-info {
							width: 100%;
							display: flex;
							font-size: 14px;
							color: #333333;
							margin-top: 10px;

							.item {
								flex: 1;
								text-align: center;
							}
						}

						.bottom-info {
							display: flex;
							height: 36px;
							margin: 20px auto 10px;

							.item {
								flex: 1;
								text-align: center;
							}
						}
					}

					.info-box {
						display: flex;
						padding-top: 20px;

						.item {
							width: 142px;
							height: 98px;
							background: #f3f7ff;
							text-align: center;
							margin-right: 6px;

							&:last-child {
								margin-right: 0;
							}

							.info:nth-child(1) {
								color: black;
								font-size: 14px;
								margin: 10px auto;
							}

							.info:nth-child(2) {
								font-size: 16px;
								font-weight: bold;
								color: #1562d6;
								margin-bottom: 10px;
							}

							.info:nth-child(3) {
								font-size: 12px;
								color: #666666;
							}
						}
					}
				}

				.other-list {
					width: 100%;
					height: 634px;
					border: 1px solid #d6d7d9;
					border-top: none;
					box-sizing: border-box;
					position: relative;

					.other-nav-list {
						height: 60px;
						width: 100%;
						display: flex;
						background: #fcfcff;
						color: #333333;

						.item {
							flex: 1;
							@include flex-center;
							cursor: pointer;

							&:hover,
							&.active {
								background: #00b6ff;
								color: #fff;
							}
						}
					}

					div.tabList {
						width: 380px;
						margin: 0 auto;
					}

					.other-tab {
						background: #f4f4f4;
						height: 30px;
						width: 100%;
						color: #666666;
						line-height: 30px;
						text-align: right;

						.tipsInfo {
							position: absolute;
							left: 310px;
							cursor: pointer;
						}

						span {
							cursor: pointer;
							margin: 10px;
							position: relative;

							&:last-child::after {
								content: "";
								position: absolute;
								left: -10px;
								width: 1px;
								height: 14px;
								top: calc(50% - 7px);
								background: #666666;
							}

							&:hover,
							&.active {
								color: #00b6ff;
							}
						}
					}

					.tabInfo {
						width: 100%;
						height: 573px;
						box-sizing: border-box;
						overflow: auto;
						font-size: 16px;

						.info {
							display: flex;
							flex-wrap: wrap;

							// grid-template-rows: repeat(auto-fill, 40px);
							.line {
								display: flex;
								height: 50px;
								width: 100%;
								align-items: center;

								.name {
									flex: 1;
									color: #333;
									text-align: right;
									font-weight: bold;
									white-space: nowrap;
								}

								.data {
									flex: 4;
									color: #333333;
									margin-left: 10px;

									&.norwap {
										white-space: nowrap;
									}
								}
							}
						}

						#chart9 {
							height: 100%;
							width: 100%;
						}
					}

					.other-info-list {
						width: 100%;
						height: 545px;
						background: #fff;
						box-sizing: border-box;
						padding: 0 10px;
						overflow-x: hidden;
						overflow-y: auto;
						border-bottom: 1px solid #d6d7d9;

						.item {
							height: 110px;
							border-bottom: 1px solid #d6d7d9;
							width: 100%;
							position: relative;

							&:last-child {
								border-bottom: none;
							}

							img {
								display: block;
								position: absolute;
								top: 14px;
								left: 9px;
								width: 105px;
								height: 80px;
								background: #00b6ff;
							}

							.address {
								position: absolute;
								top: 45px;
								left: 124px;
								color: #666;
								font-size: 14px;

								.mapIcon {
									height: 16px;
									width: 16px;
									transform: translateY(3px);
								}
							}

							.title {
								font-size: 16px;
								color: #333;
								position: absolute;
								top: 14px;
								left: 124px;
							}

							.size {
								position: absolute;
								top: 45px;
								left: 124px;
								color: #666;
								font-size: 14px;

								span {
									margin-right: 20px;
								}
							}

							.time {
								color: #333;
								position: absolute;
								top: 74px;
								left: 124px;
							}

							.priceItem {
								position: absolute;
								left: 340px;
								display: flex;
								align-items: center;
								white-space: nowrap;

								.icon {
									width: 18px;
									height: 18px;
									font-size: 12px;
									@include flex-center;
									margin-left: 7px;
									border: 1px solid #e0e0e0;
									border-radius: 2px;
								}

								.price {
									color: #ff3333;
									font-size: 16px;
									margin-right: 6px;
								}

								.dw {
									font-size: 12px;
									color: #333;
									white-space: nowrap;
								}

								&.sPrice {
									top: 14px;
								}

								&.aPrice {
									top: 43px;

									.icon {
										border-color: #ff3333;
										color: #ff3333;
									}
								}
							}
						}

						.itemT {
							height: 80px;
							border-bottom: 1px solid #d6d7d9;
							width: 100%;
							position: relative;

							&:last-child {
								border-bottom: none;
							}

							.title {
								font-size: 16px;
								position: absolute;
								top: 15px;
								left: 0;
								color: #333;
								width: 288px;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
							}

							.address {
								position: absolute;
								top: 45px;
								left: 0;
								color: #666;
								font-size: 14px;

								.mapIcon {
									height: 16px;
									width: 16px;
									transform: translateY(3px);
								}
							}

							.type {
								padding: 0 8px;
								height: 22px;
								border-radius: 2px;
								font-size: 13px;
								right: 0;
								position: absolute;

								&.type-0 {
									top: 5px;
									color: #c60a00 !important;

									font {
										color: #c60a00 !important;
									}
								}

								&.type-1 {
									top: 30px;
									color: #c60a00 !important;

									font {
										color: #c60a00 !important;
									}
								}

								&.type-2 {
									top: 55px;
									color: #c60a00 !important;

									font {
										color: #c60a00 !important;
									}
								}
							}
						}
					}

					.fanwei {
						position: absolute;
						top: 65px;
						left: 5px;
						font-size: 12px;
					}
				}
			}

			.right-top-box {
				flex: 1;
				box-sizing: border-box;
				padding-left: 10px;
				max-width: 1150px;

				.map {
					width: 100%;
					height: 651px;
					background: #eee;
				}

				.map-table {
					width: 100%;
					margin-top: 10px;
					position: relative;
					border: 1px solid #d6d7d9;

					.top-tab {
						width: 100%;
						height: 60px;
						display: flex;
						align-items: center;
						overflow: hidden;

						.item {
							height: 100%;
							margin-right: 60px;
							min-width: 100px;
							color: #333;
							position: relative;
							display: flex;
							align-items: center;
							justify-content: center;
							cursor: pointer;

							&.active,
							&:hover {
								color: #1e2e54;

								&::after {
									width: 100px;
									content: "";
									height: 4px;
									background: #1e2e54;
									position: absolute;
									bottom: 0;
									left: calc(50% - 50px);
								}
							}
						}
					}

					.more {
						position: absolute;
						bottom: 0px;
						left: 50%;
						transform: translateX(-50%);
						color: #00b6ff;
						cursor: pointer;
					}

					.tabInfo {
						width: 100%;
						height: 412px;
						box-sizing: border-box;
						overflow: auto;
						font-size: 16px;

						.info {
							display: grid;
							grid-template-columns: repeat(2, 1fr);

							// grid-template-rows: repeat(auto-fill, 40px);
							.line {
								display: flex;
								height: 40px;

								.name {
									flex: 2;
									color: #333;
									text-align: left;
									font-weight: bold;
									white-space: nowrap;
									padding-left: 30px;
								}

								.data {
									flex: 3;
									color: #333333;
									margin-left: 10px;

									.noWrap {
										display: inline-block;
										overflow: hidden;
										text-overflow: ellipsis;
										white-space: nowrap;
										width: 300px;
										text-align: left;
									}
								}
							}
						}
					}
				}
			}
		}

		.bottom-table {
			margin-top: 20px;

			.tabBox {
				background: #fcfcff;
				border: 1px solid #d6d7d9;
				height: 37px;
			}
		}
	}

	/deep/ .ivu-tooltip-rel {
		width: 350px !important;
		overflow: hidden !important;
	}
}
</style>
